import React, { useEffect } from "react";

import { FadeIn } from "./OurTeamPage.styles";

import OurTeamPageSections from "./OurTeamPageSections";
import MainContainer from "../../components/MainContainer/MainContainer";

const { HeroSection, ExecutiveTeam, ContactUs } = OurTeamPageSections;

const OurTeamPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <HeroSection />
        <ExecutiveTeam />
        <ContactUs />
      </FadeIn>
    </MainContainer>
  );
};

export default OurTeamPage;
