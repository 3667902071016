import { keyframes, styled } from "styled-components";
import { ReactComponent as Feature1 } from "../../assets/icons/NewCustomersOn-Demand.svg";
import { ReactComponent as Feature2 } from "../../assets/icons/PhotosandVideoscluded.svg";
import { ReactComponent as Feature3 } from "../../assets/icons/YouDoNothing.svg";
import { ReactComponent as Feature4 } from "../../assets/icons/WorksInStoreandOnline.svg";
import { ReactComponent as Feature5 } from "../../assets/icons/QualityGuaranteed.svg";
import { ReactComponent as Step1 } from "../../assets/icons/step1.svg";
import { ReactComponent as Step2 } from "../../assets/icons/step2.svg";
import { ReactComponent as Step3 } from "../../assets/icons/step3.svg";
import { ReactComponent as Step4 } from "../../assets/icons/step4.svg";
import { ReactComponent as Step5 } from "../../assets/icons/step5.svg";
import { ReactComponent as Correct } from "../../assets/icons/correct.svg";
import { ReactComponent as Vector } from "../../assets/icons/vector.svg";
import { ReactComponent as Vector2 } from "../../assets/icons/vector2.svg";
import { ReactComponent as Vector3 } from "../../assets/icons/vector3.svg";
import { ReactComponent as Vector4 } from "../../assets/icons/vector4.svg";
import backgroundImage from "../../assets/images/redesign/group6.png";
import backgroundImage2 from "../../assets/images/redesign/background2.png";

const fadeIn = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;

  }
`;

export const GoToTop = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fe5f55;
  position: fixed;
  bottom: 4rem;
  cursor: pointer;
  right: 4rem;
  border: 0.15rem solid #fff;

  &:hover {
    opacity: 1 !important;
    background-color: #fe5f55;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  background-color: #fff;
  align-items: center;
  width: 100%;
`;
export const FadeIn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-in;
`;

export const Section = styled.div`
  display: flex;
  overflow: ${(props) => (props.$hidden ? "hidden" : "visible")};
  width: 100%;
  max-width: 144rem;
  flex-wrap: ${(props) => (props.$column ? "unset" : "wrap")};
  flex-direction: ${(props) => (props.$column ? "column" : "row")};
  position: relative;
  min-height: ${(props) => (props.$smallHeight ? "54rem" : "100dvh")};
  padding: 4rem;
  padding-top: ${(props) => (props.$firstSection ? "12rem" : "4rem")};
  height: ${(props) => (props.$smallHeight ? "54rem" : "100%")};
  background-color: ${(props) => (props.$red ? "#FFE5E4" : "white")};
  justify-content: ${(props) =>
    props.$column ? "flex-start" : "space-around"};
  align-items: ${(props) =>
    props.$alignItemsCenter ? "center" : "flex-start"};
  @media (max-width: 2560px) {
    min-height: ${(props) => (props.$smallHeight ? "54rem" : "70dvh")};
  }
  @media (max-width: 1500px) {
    height: 100%;
    min-height: 100%;
  }

  @media (max-width: 1330px) {
    display: ${(props) => (props.$responsive ? "none" : "flex")};
  }
  @media (max-width: 1210px) {
    min-height: 100%;
  }
  @media (max-width: 490px) {
    padding: 10rem 2rem 10rem 2rem;
  }
`;

export const ShowSectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ShowSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;
export const ShowSection = styled.div`
  background-color: #ffe5e4;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  position: relative;
  align-items: center;
  gap: 2rem;

  display: none;
  @media (max-width: 1330px) {
    display: flex;
  }
`;

export const RedLine = styled.div`
  width: 0.5rem;
  height: 2.9rem;
  background: #fe5f55;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  @media (max-width: 1396px) {
    justify-content: center;
  }
`;

export const Label2 = styled.span`
  max-height: 2.9rem;
  padding: 0.9rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.$light ? "rgba(46, 49, 144, 0.1)" : "#2e3190"};
  color: ${(props) => (props.$light ? "#212121" : "#fff")};
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;
export const Label = styled.span`
  max-height: 2.9rem;
  padding: 0.9rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0 1rem 1rem 0;
  background: rgba(46, 49, 144, 0.1);

  color: #212121;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;

export const SectionTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: ${(props) => (props.$small ? "2.8rem" : "5.4rem")};
  line-height: ${(props) => (props.$small ? "unset" : "5.8rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  margin-top: ${(props) => (props.$marginTop ? "2rem" : "0rem")};
  text-transform: ${(props) => (props.$offCapitalize ? "none" : "capitalize")};
  @media (max-width: 1400px) {
    text-align: ${(props) => (props.$offCenter ? "left" : "center")};
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const StyledSectionMediumTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: 4.4rem;
  line-height: ${(props) => (props.$small ? "unset" : "5.2rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  @media (max-width: 1274px) {
    text-align: center;
  }
`;
export const SectionMediumTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: 4.4rem;
  line-height: ${(props) => (props.$small ? "unset" : "5.2rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  @media (max-width: 1274px) {
    text-align: center;
    margin-top: ${(props) => (props.$red ? "0rem" : "4rem")};
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

export const PaginationDots = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -5rem;
  gap: 0.5rem;
`;

export const PaginationDot = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? "#fe5f55" : "#ccc")};
  cursor: pointer;
`;

export const PaginationButton = styled.button`
  padding: 10px 20px;
  background-color: #fe5f55;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background-color: #d94b46;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const SectionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;

  margin-bottom: ${(props) => (props.$marginBottom ? "0.5rem" : "0rem")};
  margin-top: ${(props) => (props.$marginTopOff ? "0rem" : "2.5rem")};
  @media (max-width: 1274px) {
    justify-content: ${(props) => (props.$offCenter ? "flex-start" : "center")};

    margin-bottom: 0;
    gap: 0.5rem;
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const WhyChooseRightSide = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
`;
export const WhyChooseRightSideColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: ${(props) => (props.$offMaxWidth ? "112rem" : "55rem")};
`;
export const BasicRow = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
`;

export const StyledSubtitle = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  max-width: 61.7rem;
  margin-top: ${(props) => (!props.$marginTop ? "0rem" : "4rem")};
  text-align: ${(props) => (props.$center ? "center" : "left")};
  @media (max-width: 725px) {
    text-align: center;
  }
`;

export const SectionSubtitle = styled.span`
  color: #4d4d4d;
  font-family: ${(props) =>
    props.$bold ? "Proxima-Nova-Bold" : "Proxima-Nova-Regular"};
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  max-width: ${(props) => (props.$big ? "104rem" : "61.7rem")};
  text-align: ${(props) => (props.$big ? "center" : "left")};

  margin-top: ${(props) => (props.$marginOff ? "0rem" : "3.5rem")};
  margin-bottom: ${(props) => (props.$marginOff ? "0rem" : "3rem")};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  gap: 1rem;
  @media (max-width: 1396px) {
    justify-content: center;
  }
  @media (max-width: 477px) {
    justify-content: center;
  }
`;

export const Img1 = styled.img`
  width: 50.6rem;
  height: 46rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;

  object-fit: cover;
  @media (max-width: 520px) {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
  }
`;

export const Img2 = styled.img`
  width: 28.5rem;
  height: 26.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
`;

export const Img4 = styled.img`
  width: 27.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 29.1rem;
  border-radius: 1rem;
  object-fit: cover;
`;

export const Img3 = styled.img`
  width: 23.4rem;
  height: 22.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

  border-radius: 1rem;
  object-fit: cover;
`;

export const YellowCircleRightTop = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 50%;

  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  z-index: 3;
  right: -20rem;
  top: -20rem;
`;

export const YellowCircleLeftTop = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );
  z-index: 3;

  filter: blur(176.4499969482422px);
  position: absolute;

  left: -20rem;
  top: -20rem;
`;

export const YellowCircle = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 50%;

  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  left: ${(props) => (!props.$left ? "unset" : "-20rem")};
  right: ${(props) => (props.$left ? "unset" : "-20rem")};
  top: 50%;
  transform: translateY(-50%);
`;

export const ActiveUsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  @media (max-width: 1396px) {
    justify-content: center;
  }
`;
export const ActiveUsers = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;
export const ActiveUser = styled.img`
  width: 5.2rem;
  height: 5.2rem;
  margin-left: ${(props) => (props.$first ? "0rem" : "-2rem")};
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  border: 0.2rem solid #fff;
  transition: all 0.3s ease-in-out;

  &:hover {
    scale: 1.02;
    z-index: 2;
  }
`;
export const ActiveUserTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ActiveUserTextRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;
export const ActiveUserText = styled.span`
  color: #2e3190;
  font-family: "Proxima-Nova-Bold";
  font-size: 2.7rem;
  @media (max-width: 340px) {
    font-size: 2.2;
  }
`;
export const ActiveUserSmallText = styled.span`
  color: #172742;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  white-space: nowrap;
  @media (max-width: 340px) {
    font-size: 1.2rem;
  }
`;
export const ActiveUserPlus = styled.span`
  font-family: "Proxima-Nova-Regular";
  font-size: 2.4rem;
  color: white;
  user-select: none;
`;
export const ActiveUserCircle = styled.div`
  width: 5.2rem;
  height: 5.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: -2rem;

  border-radius: 50%;
  background-color: #2e3190;
  border: 0.2rem solid #fff;
`;

export const ActiveDot = styled.div`
  width: 1rem;
  border-radius: 50%;
  height: 1rem;
  background-color: #91c451;
`;

export const SectionLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 815px) {
    width: 100%;

    height: auto;
  }
`;

export const SectionRightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 70rem;
  width: 100%;
  @media (max-width: 1396px) {
    align-items: center;
    margin-top: 5rem;
  }
`;

export const Relative = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-height: 1570px) {
    margin-top: -20rem;
  }
  @media (max-height: 1040px) {
    margin-top: 0rem;
  }
  @media (max-width: 1700px) {
    transform: scale(0.8);
    margin-top: 0rem;
  }
  @media (max-width: 1210px) {
    transform: scale(0.65);
  }
  @media (max-width: 807px) {
    transform: scale(0.5);
    margin: -15rem 0rem;
  }
  @media (max-width: 613px) {
    transform: scale(0.4);
  }
  @media (max-width: 506px) {
    transform: scale(0.3);
  }
  @media (max-width: 400px) {
    transform: scale(0.27);
    margin: -20rem 0rem;
  }
`;

export const GifRedContainer = styled.img`
  /* width: 100%; */
  max-width: 120rem;
  height: 59.5rem;
  user-select: none;
  position: absolute;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

  border-radius: 1rem;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #fe5f55;
`;

export const ImageGlassFrame = styled.div`
  border-radius: 1rem;
  border: 0.1rem solid #f2ebff;
  background: rgba(255, 255, 255, 0.29);
  box-shadow: 0 0 5.39rem 0 rgba(0, 163, 255, 0.05);
  backdrop-filter: blur(26.94999885559082px);
  padding: 1.7rem;
  width: fit-content;
  height: fit-content;
  margin: ${(props) => (props.$marginHorizontal ? "0rem 1.5rem" : "0rem")};
`;

export const ImagesFirst = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 2;
`;

export const ImagesSecond = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  z-index: 2;
  margin-left: 2rem;
`;

export const ImagesThird = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  z-index: 2;
`;

export const StyledGif1 = styled.img`
  width: 28.5rem;
  height: 30.7rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  border-radius: 1rem;
`;

export const StyledGif2 = styled.img`
  width: 27.1rem;
  height: 38.4rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

  object-fit: cover;
  border-radius: 1rem;
`;

export const StyledGif3 = styled.img`
  width: 29.5rem;
  height: 42.6rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

  object-fit: cover;
  border-radius: 1rem;
`;

export const StyledGif4 = styled.img`
  width: 27.7rem;
  height: 34rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

  object-fit: cover;
  border-radius: 1rem;
`;
export const Div = styled.div`
  gap: 5rem;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 144rem;
  height: 100%;
  padding: 3rem;
  min-height: 70dvh;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;
export const Img5 = styled.img`
  z-index: 2;
  @media (max-width: 815px) {
    width: 100%;
    height: auto;
  }
`;

export const GifImg8 = styled.img`
  width: 18.8rem;
  height: 18.8rem;
  border-radius: 1rem;
  position: absolute;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  right: -3rem;
  bottom: 3rem;
  z-index: 2;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 4rem;
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  width: 22.7rem;
  height: 37.2rem;
  padding: 2rem;
  @media (max-width: 490px) {
    width: 100%;
    height: auto;
  }
`;

export const FeatureText = styled.span`
  color: #5f5f5f;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.7rem;
  line-height: 2.8rem; /* 164.706% */
  letter-spacing: 0.34px;
`;

export const FeatureTitle = styled.span`
  color: #212121;
  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
  margin-top: -0.5rem;
`;

export const FeatureIcon1 = styled(Feature1)`
  min-width: 7.65rem;
  max-width: 7.65rem;
  min-height: 6.1rem;
  max-height: 6.1rem;
`;
export const FeatureIcon2 = styled(Feature2)`
  min-width: 7.65rem;
  max-width: 7.65rem;
  min-height: 6.1rem;
  max-height: 6.1rem;
`;
export const FeatureIcon3 = styled(Feature3)`
  min-width: 7.65rem;
  max-width: 7.65rem;
  min-height: 6.1rem;
  max-height: 6.1rem;
`;
export const FeatureIcon4 = styled(Feature4)`
  min-width: 7.65rem;
  max-width: 7.65rem;
  min-height: 6.1rem;
  max-height: 6.1rem;
`;
export const FeatureIcon5 = styled(Feature5)`
  min-width: 7.65rem;
  max-width: 7.65rem;
  min-height: 6.1rem;
  max-height: 6.1rem;
`;

export const CustomShape = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  width: 51.8rem;
  height: 7.7rem;
  background-color: #fe5f55;
  clip-path: ${(props) =>
    props.$left
      ? "polygon(0% 0%, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 0% 100%)"
      : "polygon(40px 0%, 100% 0%, 100% 100%, 40px 100%, 0% 50%)"};

  /* clip-path: polygon(40px 0%, 100% 0%, 100% 100%, 40px 100%, 0% 50%); */

  @media (max-width: 645px) {
    width: 100%;
  }
`;

export const ShapeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 1.6rem;
  @media (max-width: 645px) {
    width: 100%;
  }
`;

export const StepsLine = styled.img`
  margin-top: 2.8rem;
`;
export const StepsLineCustom = styled.div`
  height: 266.5rem;
  margin-right: 1.1rem;
  width: 0.2rem;
  background-color: #9c9c9c;
  height: auto;
`;

export const HowItWorkContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 5rem;
`;

export const StepText = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 2.4rem;
  line-height: 150%; /* 36px */
  letter-spacing: -0.12px;
  @media (max-width: 450px) {
    font-size: 2rem;
  }
  @media (max-width: 330px) {
    font-size: 1.8rem;
  }
`;

export const HowItWorkImg = styled.img`
  width: 55.9rem;
  height: 27.5rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  margin-right: ${(props) => (!props.$left ? "0rem" : "3rem")};
  margin-left: ${(props) => (props.$left ? "0rem" : "3rem")};
  @media (max-width: 645px) {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
`;

export const HowItWorkLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4.8rem;
`;
export const StepDot = styled.div`
  background-color: #fe5f55;

  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StepDotContainer = styled.div`
  background-color: #fe5f5550;
  position: absolute;
  left: -4.75rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HowItWorkRightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
`;

export const StepIcon1 = styled(Step1)``;
export const StepIcon2 = styled(Step2)``;
export const StepIcon3 = styled(Step3)``;
export const StepIcon4 = styled(Step4)``;
export const StepIcon5 = styled(Step5)``;

export const StepIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  background-color: white;
`;

export const StepTextContainer = styled.div`
  display: flex;
  width: 55.9rem;
  height: 10.5rem;
  padding: 2rem;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-top: -2rem;
  margin-bottom: 6.7rem;
  @media (max-width: 645px) {
    width: 100%;
    height: auto;
  }
`;

export const StepInfo = styled.span`
  color: #6f6f6f;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;

  @media (max-width: 330px) {
    font-size: 1.6rem;
  }
`;

export const MarketSectorContainer = styled.div`
  border-radius: 1rem;
  background: #ffe5e4;
  display: flex;
  padding: 2rem;
  justify-content: center;
  max-width: 55rem;
  align-items: center;
`;
export const MarketSectors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 5rem;
  position: relative;
`;

export const MarketSectorText = styled.span`
  color: ${(props) => (props.$bold ? "#212121" : "#4d4d4d")};
  font-family: ${(props) =>
    props.$bold ? "Proxima-Nova-Bold" : "Proxima-Nova-Regular"};
  font-size: 1.8rem;
  line-height: 2.9rem;
`;

export const MarketSectorTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
`;

export const CorrectIcon = styled(Correct)`
  min-width: 2rem;
  min-height: 2rem;
  margin-top: 0.55rem;
`;

export const LinearGradient = styled.div`
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  height: 42.1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

export const StoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin-top: 6rem;
  width: 100%;
  margin-bottom: 10rem;
`;

export const StoryContainer = styled.div`
  display: flex;
  padding: 4rem;
  flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border-radius: 1rem;

  max-height: 46rem;
  width: 100%;
  background: ${(props) => (props.$reverse ? "#EBEBF4" : "#ffe5e4")};
  @media (max-width: 1323px) {
    flex-direction: column;
    align-self: center;
    max-height: fit-content;
    width: fit-content;
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const StoryLeftSide = styled.div`
  position: relative;
  @media (max-width: 670px) {
    width: 100%;
  }
`;
export const StoryRightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 60.7rem;
  @media (max-width: 670px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const StoryText = styled.span`
  color: ${(props) => (props.$bold ? "#212121" : "#4d4d4d")};
  font-family: ${(props) =>
    props.$bold ? "Proxima-Nova-Bold" : "Proxima-Nova-Regular"};
  font-size: 1.8rem;
  line-height: 2.9rem;
`;

export const StoryTextRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: ${(props) => (props.$marginTop ? "1.5rem" : "0rem")};
`;

export const GradientName = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.4rem;
  letter-spacing: -0.07px;
`;
export const GradientRole = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.5rem;
  letter-spacing: -0.075px;
`;
export const GradientNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;
export const GradientNameColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const GradientUserProfilePhoto = styled.img`
  width: 4.1rem;
  border-radius: 50%;
  height: 4.1rem;
  object-fit: cover;
`;

export const GradientText = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.5rem;
  line-height: 150%; /* 22.5px */
  letter-spacing: -0.075px;
`;

export const GradientContainer = styled.div`
  width: 60.7rem;
  height: 16rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  padding: 2rem;
  background: linear-gradient(93deg, #fe5f55 1.55%, #2e3190 99.38%);
  @media (max-width: 670px) {
    width: 100%;
    height: fit-content;
  }
`;

export const VectorIcon = styled(Vector)`
  position: absolute;
  left: ${(props) => (props.$right ? "unset" : "1rem")};
  right: ${(props) => (!props.$right ? "unset" : "1rem")};
  bottom: ${(props) => (!props.$right ? "unset" : "1rem")};
  top: ${(props) => (props.$right ? "unset" : "1rem")};
  transform: ${(props) => (!props.$right ? "unset" : "rotate(180deg)")};
`;

export const StoryPhoto = styled.img`
  width: 45rem;
  height: 50rem;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  @media (max-width: 670px) {
    width: 100%;
    height: auto;
  }
`;

export const StoryPhotoExample = styled.img`
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  position: absolute;
  right: -25%;
  bottom: 1rem;
`;

export const SeeMoreSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
  background: linear-gradient(0deg, #fd6056 0%, #fd6056 100%);
  width: 100%;
  max-width: 144rem;
  height: 34.3rem;
  overflow: hidden;
  background: url(${backgroundImage});
  @media (max-width: 670px) {
    width: 100%;
    height: fit-content;
    padding: 3rem;
  }
`;

export const StyledSeeMoreSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;

  width: 100%;
  max-width: 144rem;
  height: 34.3rem;
  padding: 2rem;
  overflow: hidden;
  background: url(${backgroundImage2});
`;

export const VectorIcon2 = styled(Vector3)`
  transform: rotate(15deg);

  position: absolute;
  left: 0rem;
  bottom: 0rem;
  z-index: 2;
  @media (max-width: 670px) {
    display: none;
  }
`;
export const VectorIcon3 = styled(Vector2)`
  position: absolute;
  right: 2.4rem;
  bottom: 0.8rem;
  z-index: 2;
  @media (max-width: 670px) {
    display: none;
  }
`;

export const SeeMoreText = styled.span`
  color: #fff;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  font-size: 3.5rem;
  line-height: 4.2rem; /* 120% */
  text-transform: capitalize;
  max-width: 71rem;
  @media (max-width: 670px) {
    font-size: 3rem;
    line-height: 3.2rem;
  }
`;

export const LeftPhotoAbsolute = styled.img`
  width: 22.5rem;
  height: 22.5rem;
  border-radius: 1rem;
  object-fit: cover;
  transform: rotate(-16.046deg);
  left: 10rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  position: absolute;
  @media (max-width: 1415px) {
    display: none;
  }
`;
export const RightPhotoAbsolute = styled.img`
  object-fit: cover;
  width: 17.7rem;
  border-radius: 1rem;
  height: 17.7rem;
  right: 10rem;
  position: absolute;
  /* transform: rotate(13.948deg); */
  filter: brightness(120%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const SeeMoreSmallText = styled.span`
  color: #fff;
  margin-top: 2rem;
  text-align: center;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.8rem; /* 120% */
  text-transform: capitalize;
`;

export const SeeMoreButton = styled.button`
  border-radius: 1rem;
  border: 0.1rem solid #fff;
  background: #fff;
  height: 4.8rem;
  min-width: 26rem;
  width: fit-content;
  padding: 0rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: black;
  outline: none;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: "Proxima-Nova-Bold";
  transition: all 0.3s ease-in-out;
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  &:active {
    scale: 0.99;
  }
`;

export const RequestDemoLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 61.1rem;
`;
export const RequestDemoRightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 56rem;
  @media (max-width: 1250px) {
    margin-top: 5rem;
  }
  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`;
export const RequestDemoTextBox = styled.div`
  padding: 1.5rem 2.5rem;
  border-radius: 1rem;
  background: #ffe5e4;
`;
export const RequestDemoTextInBox = styled.span`
  color: #4d4d4d;

  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
`;

export const RequestDemoText = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
`;

export const RequestTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
`;

export const RequestDemoImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const RequestDemoImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 600px) {
    width: 50%;
  }
`;

export const RequestDemoImageSmall = styled.img`
  width: 26.9rem;
  height: 27.8rem;
  border-radius: 0.8rem;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  object-fit: cover;
  @media (max-width: 600px) {
    height: auto;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
`;

export const RequestDemoImageBig = styled.img`
  width: 26.9rem;
  height: 40.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
  @media (max-width: 600px) {
    height: auto;
    width: 100%;
    aspect-ratio: 9 / 16;
  }
`;

export const WhyChooseRedContainer = styled.div`
  display: flex;
  padding: 3.2rem;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  gap: 4.5rem;
  align-items: center;
  border-radius: 1rem;
  background: ${(props) => (props.$reverse ? "#EBEBF4" : "#ffe5e4")};
  flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
  margin-top: 5rem;
  margin-bottom: ${(props) => (props.$marginBottom ? "4rem" : "0rem")};
  flex-wrap: wrap;
  min-height: ${(props) => (props.$minHeight ? "123rem" : "unset")};
  @media (max-width: 1274px) {
    align-items: unset;
  }
`;

export const Img11 = styled.img`
  width: 53.6rem;
  height: 49.4rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    aspect-ration: 1 / 1;
  }
`;

export const ImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
`;

export const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const ImageGroup4 = styled.img`
  width: 52.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 30.3rem;
  object-fit: cover;
  border-radius: 1rem;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    aspect-ration: 1 / 1;
  }
`;

export const Img12 = styled.img`
  width: ${(props) => (props.$small ? "25.2rem" : "25.4rem")};
  height: ${(props) => (props.$small ? "17.6rem" : " 41.5rem")};
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    aspect-ration: 1 / 1;
  }
`;
export const Img13 = styled.img`
  width: 52.8rem;
  height: 30.3rem;
  object-fit: cover;
  border-radius: 1rem;
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem;
  @media (max-width: 1078px) {
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
  }
`;

export const QuestionColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0rem;
  gap: 2rem;
  max-width: 49%;
  border-bottom: 0.1rem solid rgba(53, 53, 53, 0.2);
  height: ${(props) => (props.$isOpen ? "21.2rem" : "9.5rem")};
  transition: all 0.3s ease-in-out;
  height: auto;
  @media (max-width: 1078px) {
    max-width: 100%;
  }
`;
export const QuestionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;
`;

export const Answer = styled.span`
  color: #656565;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.8rem; /* 155.556% */

  height: ${(props) => (props.$active ? "15rem" : 0)};
  opacity: ${(props) => (props.$active ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  @media (max-width: 1350px) {
    height: ${(props) => (props.$active ? "22rem" : 0)};
  }
  @media (max-width: 1078px) {
    height: ${(props) => (props.$active ? "15rem" : 0)};
  }
  @media (max-width: 670px) {
    height: ${(props) => (props.$active ? "17rem" : 0)};
  }
  @media (max-width: 550px) {
    height: ${(props) => (props.$active ? "19rem" : 0)};
  }
  @media (max-width: 470px) {
    height: ${(props) => (props.$active ? "22rem" : 0)};
  }
  @media (max-width: 430px) {
    height: ${(props) => (props.$active ? "25rem" : 0)};
  }
  @media (max-width: 410px) {
    height: ${(props) => (props.$active ? "29rem" : 0)};
  }
  @media (max-width: 315px) {
    height: ${(props) => (props.$active ? "33rem" : 0)};
  }
`;

export const Question = styled.span`
  color: #24273d;
  pointer-events: none;

  font-family: "Proxima-Nova-Bold";
  font-size: 2.2rem;
  max-width: calc(100% - 4.8rem - 2rem);

  line-height: 3.6rem; /* 163.589% */
  @media (max-width: 425px) {
    font-size: 2rem;
  }
  @media (max-width: 345px) {
    font-size: 1.8rem;
  }
`;

export const PlusContainer = styled.div`
  display: flex;
  min-width: 4.8rem;
  max-width: 4.8rem;
  cursor: pointer;
  min-height: 4.8rem;
  max-height: 4.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  z-index: 5;
  border: 0.1rem solid rgba(0, 68, 255, 0.2);
`;

const iconOut = keyframes`
  from {
    opacity: 1;
    transform: rotate(0deg);
  }
  to {
    opacity: 0;
    transform: rotate(-180deg);
  }
`;

const iconIn = keyframes`
  from {
    opacity: 0;
    transform: rotate(180deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
`;

export const Plus = styled.span`
  color: #24273d;
  cursor: pointer;
  user-select: none;

  animation: ${(props) => (!props.$active ? iconIn : iconOut)} 0.2s ease-in-out;
  font-family: "Proxima-Nova-Bold";
  font-size: 2.2rem;

  line-height: 3.6rem; /* 163.589% */
`;

export const GeneralQuestionLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledSectionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;

  margin-bottom: ${(props) => (props.$marginBottom ? "0.5rem" : "0rem")};
  margin-top: ${(props) => (props.$marginTopOff ? "0rem" : "2.5rem")};
  @media (max-width: 1200px) {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 0rem;
    gap: 0rem;
  }
`;
export const HaveMoreQuestionContainer = styled.div`
  border-radius: 1rem;
  height: 11.7rem;
  width: 100%;
  max-width: 49%;
  background: #ffe5e4;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-around;
  flex-direction: row;
  padding: 0rem 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  margin-top: 1.5rem;
  @media (max-width: 1078px) {
    max-width: 100%;
    /* padding: 0.5rem 2rem;
    max-width: 100%;
    height: fit-content; */
  }
`;
export const HaveMoreTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HaveMoreTitle = styled.span`
  color: #4d4d4d;
  white-space: nowrap;

  font-family: "Proxima-Nova-Bold";
  font-size: 2.2rem;
  line-height: 2.9rem;
  letter-spacing: 0.44px;
`;
export const HaveMoreText = styled.span`
  color: #4d4d4d;

  font-family: "Proxima-Nova-Regular";
  font-size: 2rem;
  line-height: 2.9rem;
  letter-spacing: 0.44px;
`;

export const VectorIcon4 = styled(Vector4)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const Img14 = styled.img`
  width: 54.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 70.3rem;
  object-fit: cover;
  border-radius: 1rem;
`;

export const Img15 = styled.img`
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  margin-right: -9rem;
  @media (max-width: 1365px) {
    margin-right: 0rem;
  }
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }
`;

export const Img16 = styled.img`
  object-fit: cover;
  width: 54.1rem;
  height: 41.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
  }
`;

export const BringTheCrowdLeftSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OurPromiseText = styled.span`
  color: ${(props) =>
    props.$bold ? "#212121" : props.$red ? "#FE5F55" : "#575757"};
  font-family: ${(props) =>
    props.$bold ? "Proxima-Nova-Bold" : "Proxima-Nova-Regular"};
  font-size: ${(props) => (props.$red ? "3.5rem" : "1.8rem")};
  margin-top: ${(props) => (props.$red ? "-0.7rem" : "0rem")};
`;
export const OurPromiseContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 4rem 2.65rem;
  border-radius: 1rem;
  margin-bottom: 4rem;
  gap: 0.5rem;
  flex-direction: row;
  max-width: 120rem;
  background: rgba(255, 255, 255, 0.7);
`;
