import React, { useState } from "react";
import {
  LabelContainer,
  RedLine,
  Section,
  SectionTitle,
  SectionTitleRow,
  SectionSubtitle,
  YellowCircle,
  StyledButton,
  BgRed,
  Label2,
  PricingCard,
  PricingCards,
  Logo,
  PackageType,
  PackageDescription,
  HorizontalLine,
  Price,
  PriceText,
  PerColumn,
  PerContainer,
  CorrectIcon,
  LightText,
  InfoText,
  Star,
  InfoContainer,
  Row,
  Buttons,
  ButtonPackage,
  CustomPackageLabel,
  YellowCircleTopRight,
  StyledLabelContainer,
  RangeInput,
  Tooltip,
  SliderContainer,
  MultipleLocationsContainer,
  MultipleLocationsTitle,
  MultipleLocationsRightSide,
  MultipleLocationsText,
  MultipleLocationsButton,
  StyledHorizontalLine,
  TotalPrice,
  TotalPriceContainer,
  TotalPriceText,
  OrangeCircleTopRight,
  HowItWorkLeftSide,
  Step,
  StepLine,
  Steps,
  StepText,
  StepTextContainer,
  LeftSide,
  HowItWorksImagesColumn,
  HowItWorksImages,
  Img1,
  Img2,
  BgRed2,
} from "./PricingPage.styles";

import Image1 from "../../assets/images/redesign/image20.jpeg";
import Image2 from "../../assets/images/redesign/image21.jpeg";
import Image3 from "../../assets/images/redesign/image22.jpeg";

import Button from "../../components/utils/Button/Button";
import ToggleSwitch from "../../components/utils/ToggleSwitch";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    width: "90%",
    maxWidth: "700px",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    border: "none",
    background: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
};

const PricingPageSections = {
  HeroSection: () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
      <Section $alignItemsCenter $column $firstSection>
        {isModalOpen && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <button
                style={modalStyles.closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                ✖
              </button>
              <iframe
                src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                style={{ border: 0, width: "100%", height: "500px" }}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        )}
        <BgRed />
        <BgRed2 />
        {/* <ToggleSwitch /> */}
        <SectionTitleRow>
          <SectionTitle $red>EyCrowd</SectionTitle>
          <SectionTitle>Pricing</SectionTitle>
        </SectionTitleRow>
        <SectionSubtitle $big style={{ maxWidth: 880 }}>
          Welcome to EyCrowd's transparent, price-per-visit pricing page. We
          believe in keeping things simple so you can focus on growing your
          business without any hidden fees. Choose from our flexible weekly or
          monthly packages designed to suit your specific needs.
        </SectionSubtitle>

        <YellowCircle />
        <LabelContainer $marginTop>
          <RedLine />
          <Label2 $light>Per Location Packages</Label2>
          <RedLine />
        </LabelContainer>
        <SectionTitleRow>
          <SectionTitle>Weekly</SectionTitle>
          <SectionTitle $red>Packages</SectionTitle>
        </SectionTitleRow>
        <SectionSubtitle $big style={{ maxWidth: 880 }}>
          Our single location-focused weekly packages are perfect for businesses
          looking to consistently increase foot traffic within a short
          timeframe. Each package is carefully designed to deliver a targeted
          number of visitors per week, all at an exceptional value.
        </SectionSubtitle>
        <PricingCards>
          <PricingCard>
            <Logo />
            <PackageType>Bronze Package</PackageType>
            <PackageDescription $margin>
              A budget-friendly, professional bronze package perfect for
              business startups.
            </PackageDescription>

            <HorizontalLine />

            <Price>$999</Price>
            <PriceText>Total Price per Week</PriceText>

            <HorizontalLine />

            <PerColumn>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Price per Visitor</PriceText>
                <Price $small>$24.99</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Visitors per Week</PriceText>
                <Price $small>40</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Authentic UGC Pieces</PriceText>
                <Price $small>80</Price>
              </PerContainer>
            </PerColumn>
            <StyledButton $marginTop onClick={() => setIsModalOpen(true)}>
              Book a Quick Call
            </StyledButton>
            <LightText>
              To finalize your purchase, confirm the location, and enjoy your
              weekly growth!
            </LightText>
          </PricingCard>
          <PricingCard>
            <Logo />
            <PackageType>Silver Package</PackageType>
            <PackageDescription $margin>
              A budget-friendly, professional silver package perfect for
              business startups.
            </PackageDescription>

            <HorizontalLine />

            <Price>$1,919</Price>
            <PriceText>Total Price per Week</PriceText>

            <HorizontalLine />

            <PerColumn>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Price per Visitor</PriceText>
                <Price $small>$23.99</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Visitors per Week</PriceText>
                <Price $small>80</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Authentic UGC Pieces</PriceText>
                <Price $small>160</Price>
              </PerContainer>
            </PerColumn>
            <StyledButton $marginTop onClick={() => setIsModalOpen(true)}>
              Book a Quick Call
            </StyledButton>
            <LightText>
              To finalize your purchase, confirm the location, and enjoy your
              weekly growth!
            </LightText>
          </PricingCard>

          <PricingCard>
            <Logo />
            <PackageType>Gold Package</PackageType>
            <PackageDescription $margin>
              A budget-friendly, professional gold package perfect for business
              startups.
            </PackageDescription>

            <HorizontalLine />

            <Price>$2,758</Price>
            <PriceText>Total Price per Week</PriceText>

            <HorizontalLine />

            <PerColumn>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Price per Visitor</PriceText>
                <Price $small>$22.99</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Visitors per Week</PriceText>
                <Price $small>120</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Authentic UGC Pieces</PriceText>
                <Price $small>240</Price>
              </PerContainer>
            </PerColumn>
            <StyledButton $marginTop onClick={() => setIsModalOpen(true)}>
              Book a Quick Call
            </StyledButton>
            <LightText>
              To finalize your purchase, confirm the location, and enjoy your
              weekly growth!
            </LightText>
          </PricingCard>
        </PricingCards>
        <InfoContainer>
          <Star>*</Star>
          <InfoText>
            After clicking the button, users will be prompted to enter their
            location address, contact information, and payment details (credit
            card or bank account). Once the transaction is processed, they can
            select a convenient date and time for a call or Zoom meeting to
            confirm the details. The entire process takes just 10 minutes.
          </InfoText>
        </InfoContainer>
      </Section>
    );
  },
  MonthlySection: () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <Section $alignItemsCenter $column $red>
        {isModalOpen && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <button
                style={modalStyles.closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                ✖
              </button>
              <iframe
                src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                style={{ border: 0, width: "100%", height: "500px" }}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        )}
        <SectionTitleRow>
          <SectionTitle>Monthly</SectionTitle>
          <SectionTitle $red>Packages</SectionTitle>
        </SectionTitleRow>
        <SectionSubtitle $big style={{ maxWidth: 880 }}>
          For those who prefer a longer-term plan, our single location monthly
          packages offer even greater value. Enjoy a higher volume of visitors
          at a reduced price per visit, making it easier to plan your budget
          over the course of the month.
        </SectionSubtitle>

        <PricingCards>
          <PricingCard $blueGradient>
            <Logo />
            <PackageType $yellow>Bronze Package</PackageType>
            <PackageDescription $margin>
              A budget-friendly, professional bronze package perfect for
              business startups.
            </PackageDescription>

            <HorizontalLine />

            <Price>$3,518</Price>
            <PriceText>Total Price per Week</PriceText>

            <HorizontalLine />

            <PerColumn>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Price per Visitor</PriceText>
                <Price $small>$21.99</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Visitors per Month</PriceText>
                <Price $small>160</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Authentic UGC Pieces</PriceText>
                <Price $small>320</Price>
              </PerContainer>
            </PerColumn>
            <StyledButton $marginTop onClick={() => setIsModalOpen(true)}>
              Book a Quick Call
            </StyledButton>
            <LightText>
              To finalize your purchase, confirm the location, and enjoy your
              weekly growth!
            </LightText>
          </PricingCard>

          <PricingCard $blueGradient>
            <Logo />
            <PackageType $yellow>Silver Package</PackageType>
            <PackageDescription $margin>
              A budget-friendly, professional silver package perfect for
              business startups.
            </PackageDescription>

            <HorizontalLine />

            <Price>$6,716</Price>
            <PriceText>Total Price per Month</PriceText>

            <HorizontalLine />

            <PerColumn>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Price per Visitor</PriceText>
                <Price $small>$20.99</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Visitors per Month</PriceText>
                <Price $small>320</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Authentic UGC Pieces</PriceText>
                <Price $small>640</Price>
              </PerContainer>
            </PerColumn>
            <StyledButton $marginTop onClick={() => setIsModalOpen(true)}>
              Book a Quick Call
            </StyledButton>
            <LightText>
              To finalize your purchase, confirm the location, and enjoy your
              weekly growth!
            </LightText>
          </PricingCard>

          <PricingCard $blueGradient>
            <Logo />
            <PackageType $yellow>Gold Package</PackageType>
            <PackageDescription $margin>
              A budget-friendly, professional gold package perfect for business
              startups.
            </PackageDescription>

            <HorizontalLine />

            <Price>$9,595</Price>
            <PriceText>Total Price per Week</PriceText>

            <HorizontalLine />

            <PerColumn>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Price per Visitor</PriceText>
                <Price $small>$19.99</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Visitors per Week</PriceText>
                <Price $small>480</Price>
              </PerContainer>
              <PerContainer>
                <CorrectIcon />
                <PriceText $marginTopOff>Authentic UGC Pieces</PriceText>
                <Price $small>960</Price>
              </PerContainer>
            </PerColumn>
            <StyledButton $marginTop onClick={() => setIsModalOpen(true)}>
              Book a Quick Call
            </StyledButton>
            <LightText>
              To finalize your purchase, confirm the location, and enjoy your
              weekly growth!
            </LightText>
          </PricingCard>
        </PricingCards>
        <InfoContainer $light>
          <Row>
            <Star>*</Star>
            <InfoText>
              After clicking the button, users will be prompted to enter their
              location address, contact information, and payment details (credit
              card or bank account). Once the transaction is processed, they can
              select a convenient date and time for a call or Zoom meeting to
              confirm the details. The entire process takes just 10 minutes.
            </InfoText>
          </Row>
          <Row>
            <Star>*</Star>
            <InfoText>
              The user will click the purchase button, initiating the
              transaction process.
            </InfoText>
          </Row>
          <Row>
            <Star>*</Star>
            <InfoText>
              Once the payment is processed, they can schedule a convenient date
              and time for a call or Zoom meeting to confirm the details. The
              entire process is designed to be efficient, taking no more than 10
              minutes.
            </InfoText>
          </Row>
        </InfoContainer>
      </Section>
    );
  },
  CustomPackageSection: () => {
    const [customPackage, setCustomPackage] = useState("monthly");
    const [value, setValue] = useState(200);
    const min = 160;
    const max = 480;

    const handleChange = (e) => {
      setValue(e.target.value);
    };

    return (
      <Section $column $alignItemsCenter $hidden>
        <YellowCircleTopRight />
        <SectionTitleRow>
          <SectionTitle>Custom</SectionTitle>
          <SectionTitle $red>Package</SectionTitle>
        </SectionTitleRow>
        <SectionSubtitle $big style={{ maxWidth: 880 }}>
          Create a custom single location plan that perfectly suits your needs.
          Whether you need a flexible visitor count or prefer a specific pricing
          structure, a custom package adapts to your needs.
        </SectionSubtitle>
        <Buttons>
          <ButtonPackage
            $right
            $active={customPackage === "monthly"}
            onClick={() => {
              setCustomPackage("monthly");
            }}
          >
            Monthly
          </ButtonPackage>
          <ButtonPackage
            $active={customPackage === "weekly"}
            onClick={() => {
              setCustomPackage("weekly");
            }}
          >
            Weekly
          </ButtonPackage>
        </Buttons>

        <SliderContainer>
          <StyledLabelContainer>
            <CustomPackageLabel>Visitor per month</CustomPackageLabel>
          </StyledLabelContainer>

          <Tooltip>{value}</Tooltip>

          <RangeInput
            type="range"
            min={min}
            max={max}
            value={value}
            onChange={handleChange}
          />

          <StyledLabelContainer>
            <span>{min}</span>
            <span>{max}</span>
          </StyledLabelContainer>
        </SliderContainer>

        <Button title={"Calculate Price"} />

        <MultipleLocationsContainer>
          <TotalPriceContainer>
            <TotalPrice>$3,520</TotalPrice>
            <TotalPriceText>Total Price per Month</TotalPriceText>
          </TotalPriceContainer>
          <StyledHorizontalLine />
          <MultipleLocationsRightSide>
            <LabelContainer>
              <RedLine />
              <Label2 $offBorder>Multiple Locations</Label2>
            </LabelContainer>
            <MultipleLocationsTitle>
              Custom Pricing for Multiple Locations
            </MultipleLocationsTitle>
            <MultipleLocationsText>
              Growing multiple locations? We've got the perfect plan for you!
              Give us a call and discover custom pricing options designed just
              for your needs. It only takes 10 minutes to get started—quick,
              simple, and stress-free. Don't miss out—reach out today and find
              the ideal solution to elevate your business!
            </MultipleLocationsText>
            <MultipleLocationsButton>Book a Quick Call</MultipleLocationsButton>
          </MultipleLocationsRightSide>
        </MultipleLocationsContainer>
      </Section>
    );
  },
  HowItWorksSection: () => {
    return (
      <Section $alignItemsCenter>
        <OrangeCircleTopRight />
        <YellowCircleTopRight />
        <LeftSide>
          <SectionTitleRow>
            <SectionTitle>How It</SectionTitle>
            <SectionTitle $red>Works</SectionTitle>
          </SectionTitleRow>
          <HowItWorkLeftSide>
            <Steps>
              <Step>1</Step>
              <StepLine />
              <Step>2</Step>
              <StepLine $small />
              <Step>3</Step>
            </Steps>
            <StepTextContainer>
              <StepText>
                At EyCrowd, you pay only for the visitors who try your products
                and services. Our price-per-visit model ensures that every
                dollar you invest is directly tied to customer engagement.
                Whether you choose a weekly or monthly package, you'll benefit
                from predictable pricing and scalable options tailored to your
                business goals.
              </StepText>
              <StepText>
                For more details or to discuss a custom solution that fits your
                needs, please contact our sales team. We're here to help you
                maximize every visit!
              </StepText>
              <StepText $marginTop>
                Choose the package that best suits your business and start
                driving real results with EyCrowd today!
              </StepText>
            </StepTextContainer>
          </HowItWorkLeftSide>
        </LeftSide>
        <HowItWorksImages>
          <HowItWorksImagesColumn>
            <Img1 src={Image1} alt="EyCrowd - Photo" />
            <Img1 src={Image2} alt="EyCrowd - Photo" />
          </HowItWorksImagesColumn>
          <Img2 src={Image3} alt="EyCrowd - Photo" />
        </HowItWorksImages>
      </Section>
    );
  },
};

export default PricingPageSections;
