import { keyframes, styled } from "styled-components";
import { ReactComponent as Correct } from "../../assets/icons/correctWhite.svg";
import { ReactComponent as LogoSvg } from "../../assets/icons/eycTransparent.svg";
import { ReactComponent as OurVision } from "../../assets/icons/ourVision.svg";
import { ReactComponent as OurMission } from "../../assets/icons/ourMission.svg";
import { ReactComponent as Background } from "../../assets/icons/vector5.svg";
import { ReactComponent as BlueDot } from "../../assets/icons/blueDot.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;

  }
`;

export const FadeIn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-in;
`;
export const BgRed = styled(Background)`
  position: absolute;
  top: 7rem;
  right: 0;
  left: 0;
`;
export const BgRed2 = styled.div`
  position: absolute;
  top: 0;
  height: 10rem;
  right: 0;
  left: 0;
  background-color: #ffe5e4;
  width: 100%;
`;
export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  background-color: #fff;
  align-items: center;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  overflow: ${(props) => (props.$hidden ? "hidden" : "visible")};
  width: 100%;
  max-width: 144rem;
  flex-direction: ${(props) => (props.$column ? "column" : "row")};
  position: relative;
  min-height: ${(props) => (props.$smallHeight ? "54rem" : "100dvh")};
  padding: 4rem;
  padding-top: ${(props) => (props.$firstSection ? "12rem" : "4rem")};
  height: ${(props) => (props.$smallHeight ? "54rem" : "100%")};
  background-color: ${(props) => (props.$red ? "#FFE5E4" : "white")};
  justify-content: ${(props) =>
    props.$column ? "flex-start" : "space-around"};
  align-items: ${(props) =>
    props.$alignItemsCenter ? "center" : "flex-start"};
  @media (max-width: 2560px) {
    min-height: ${(props) => (props.$smallHeight ? "54rem" : "70dvh")};
  }
  @media (max-width: 1500px) {
    min-height: ${(props) => (props.$smallHeight ? "54rem" : "100dvh")};
  }
  @media (max-width: 1300px) {
    flex-wrap: wrap;
    min-height: 100%;
    height: 100%;
  }
  @media (max-width: 420px) {
    padding-left: ${(props) => (props.$padding ? "2rem" : "4rem")};
  }
`;

export const SectionSubtitle = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  max-width: ${(props) => (props.$big ? "104rem" : "61.7rem")};
  text-align: ${(props) => (props.$big ? "center" : "left")};
  z-index: 2;

  margin-top: 3.5rem;
  margin-bottom: 3rem;
`;
export const RedLine = styled.div`
  width: 5px;
  height: 29px;
  background: #fe5f55;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  margin-top: ${(props) => (props.$marginTop ? "16rem" : "0rem")};
  gap: 0.3rem;
`;

export const Label2 = styled.span`
  max-height: 2.9rem;
  padding: 0.9rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: ${(props) =>
    props.$offBorder ? "0rem .5rem .5rem 0rem" : "0.5rem"};
  background: ${(props) =>
    props.$light ? "rgba(46, 49, 144, 0.1)" : "#2e3190"};
  color: ${(props) => (props.$light ? "#212121" : "#fff")};
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;
export const Label = styled.span`
  width: 38.7rem;
  height: 61.6rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0 1rem 1rem 0;
  background: rgba(46, 49, 144, 0.1);

  color: #212121;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;

export const SectionTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: ${(props) => (props.$small ? "2.8rem" : "5.4rem")};
  line-height: ${(props) => (props.$small ? "unset" : "5.8rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  text-transform: capitalize;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;
export const SectionMediumTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: 4.4rem;
  line-height: ${(props) => (props.$small ? "unset" : "5.2rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  text-transform: capitalize;
`;

export const SectionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: ${(props) => (props.$offGap ? "0rem" : "1.2rem")};
  z-index: 2;
  margin-bottom: ${(props) => (props.$marginBottom ? "0.5rem" : "0rem")};
  margin-top: ${(props) => (props.$marginTopOff ? "0rem" : "2.5rem")};
  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const YellowCircleTopRight = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;

  right: -20rem;
  top: -20rem;
`;

export const YellowCircleBottomRight = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;

  right: -20rem;
  bottom: -20rem;
`;

export const OrangeCircleTopRight = styled.div`
  width: 41.1rem;
  height: 40.9rem;

  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(254, 96, 86, 0.4) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;

  left: -20rem;
  top: -20rem;
`;

export const OrangeCircleLeft = styled.div`
  width: 41.1rem;
  height: 40.9rem;

  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(254, 96, 86, 0.4) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;

  left: -20rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const YellowCircle = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 411px;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  left: ${(props) => (!props.$left ? "unset" : "-20rem")};
  right: ${(props) => (props.$left ? "unset" : "-20rem")};
  top: 50%;
  transform: translateY(-50%);
`;

export const PricingCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;
export const PricingCard = styled.div`
  width: 38.7rem;
  height: 60rem;
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;
  gap: 2rem;

  border-radius: 1rem;
  background: ${(props) =>
    props.$blueGradient
      ? "linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(46, 49, 144, 0.30) 100%), #FE5F55"
      : "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.3) 100%), #fe5f55"};
  position: relative;
`;

export const Logo = styled(LogoSvg)`
  position: absolute;
  top: 2rem;
  right: 0;
`;

export const PackageType = styled.span`
  color: #212121;

  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.5rem;
  line-height: 5.2rem; /* 346.667% */
  letter-spacing: 1.8px;
  text-transform: uppercase;
  display: flex;
  padding: 0 1.2rem;
  max-height: 4.8rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: ${(props) => (props.$yellow ? "#FFC324" : "#FFF")};
`;

export const PackageDescription = styled.span`
  color: #fff;

  font-family: "Proxima-Nova-Regular";
  margin-top: ${(props) => (props.$margin ? "0.5rem" : "0rem")};
  margin-bottom: ${(props) => (props.$margin ? "1rem" : "0rem")};
  font-size: 1.6rem;
  max-width: 90%;
  line-height: 2.5rem; /* 156.25% */
`;

export const LightText = styled.span`
  color: #fff;

  text-align: center;
  font-family: "Proxima-Nova-Regular";

  font-size: 1.3rem;
  line-height: 2rem; /* 156.25% */
`;

export const HorizontalLine = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
`;

export const Price = styled.span`
  color: #fff;

  font-family: "Proxima-Nova-Bold";
  font-size: ${(props) => (props.$small ? "2.5rem" : "5.5rem")};
  line-height: 5.2rem; /* 94.545% */
  letter-spacing: -0.11rem;
`;

export const PriceText = styled.span`
  color: #fff;

  margin-top: ${(props) => (props.$marginTopOff ? "0rem" : "-2rem")};
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  line-height: 2.5rem; /* 94.545% */
  letter-spacing: -0.11rem;
`;

export const PerColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const PerContainer = styled.div`
  display: flex;
  padding: 0rem 1.2rem;
  align-items: center;
  flex-direction: row;
  gap: 0.6rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.31);
`;

export const CorrectIcon = styled(Correct)``;

export const StyledButton = styled.button`
  border-radius: 1rem;
  border: 0.1rem solid #fff;
  background: #fff;
  height: 4.8rem;
  min-width: 26rem;
  width: 100%;
  padding: 0rem 2rem;
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: black;
  outline: none;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: "Proxima-Nova-Bold";
  transition: all 0.3s ease-in-out;
  margin-top: ${(props) => (props.$marginTop ? "1.5rem" : "0rem")};
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  &:active {
    scale: 0.99;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  margin-left: -3rem;
  align-items: center;
  @media (max-width: 475px) {
    margin-left: -3.2rem;
  }
`;
export const InfoContainer = styled.div`
  border-radius: 1rem;
  width: 100%;
  padding: 2.5rem;
  margin-top: 5rem;
  gap: ${(props) => (props.$light ? "3.5rem" : "0.6rem")};
  margin-bottom: 6rem;
  display: flex;
  background-color: ${(props) =>
    props.$light ? "rgba(255, 255, 255, 0.70)" : "#ebebf4"};
  flex-direction: ${(props) => (props.$light ? "column" : "row")};
`;

export const Star = styled.span`
  color: #fe5f55;
  margin-top: 0.65rem;

  font-family: "Proxima-Nova-Bold";
  font-size: 3.5rem;
  line-height: 2.9rem; /* 82.857% */
`;
export const InfoText = styled.span`
  color: #575757;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 82.857% */
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonPackage = styled.button`
  border-radius: 0.47rem;
  border: 0.12rem solid #2e3190;
  background: ${(props) => (!props.$active ? "#fff" : "#2e3190")};
  outline: none;
  cursor: pointer;

  color: ${(props) => (!props.$active ? "#000" : "#fff")};
  z-index: ${(props) => (props.$active ? 2 : 1)};
  text-align: center;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.8rem;
  line-height: 2.82rem; /* 156.502% */
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: ${(props) => (props.$right ? "0rem" : "-1rem")};
  border-bottom-right-radius: ${(props) => (props.$right ? "0rem" : "-1rem")};
  border-top-left-radius: ${(props) => (!props.$right ? "0rem" : "-1rem")};
  border-bottom-left-radius: ${(props) => (!props.$right ? "0rem" : "-1rem")};
`;

export const CustomPackageLabel = styled.span`
  margin-top: 4rem;
  margin-bottom: 3rem;

  color: #212121;
  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 6rem;
`;

export const RangeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 80%;
  height: 0.6rem;
  background: #b3b3b3;
  border-radius: 0.5rem;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2.4rem;
    height: 2.4rem;
    background: white;
    border: 0.3rem solid #e74c3c;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
`;

export const Tooltip = styled.div`
  background: white;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  /* border: 1px solid gray; */
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 1.4rem;
  color: #333;
  margin-top: 0.5rem;
`;

export const MultipleLocationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  background: #ebebf4;
  width: 100%;
  padding: 3rem 4rem;
  max-width: 120rem;
  margin-top: 8rem;
`;

export const MultipleLocationsTitle = styled.span`
  color: #212121;

  font-family: "Proxima-Nova-Bold";
  font-size: 2.4rem;

  margin-bottom: 1.5rem;
`;

export const MultipleLocationsRightSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 84rem;
  gap: 2rem;
`;

export const MultipleLocationsText = styled.span`
  color: #575757;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.7rem;
  line-height: 2.9rem; /* 170.588% */
`;

export const MultipleLocationsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: 0.1rem solid black;
  height: 4.8rem;

  color: #212121;
  font-family: "Proxima-Nova-SemiBold";

  max-width: 13rem;
  font-size: 1.6rem;

  cursor: pointer;
  outline: none;
  user-select: none;
  transition: all 0.3s ease-in-out;
  &:active {
    scale: 0.99;
    background-color: black;
    color: white;
  }
`;

export const StyledHorizontalLine = styled.div`
  width: 0.1rem;
  height: 22rem;
  margin-right: 3.4rem;
  margin-left: 3.4rem;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 22rem;
`;
export const TotalPrice = styled.span`
  color: #fe5f55;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  font-size: 4.7rem;
  line-height: 5.2rem; /* 110.638% */
  letter-spacing: -0.94px;
`;
export const TotalPriceText = styled.span`
  color: #4e4e4e;
  text-align: center;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  line-height: 2.5rem; /* 110.638% */
`;

export const HowItWorkLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  gap: 2.7rem;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.9rem;
`;

export const Step = styled.span`
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 50%;
  background-color: #fe5f55;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.8rem;
`;

export const StepLine = styled.div`
  width: 0.1rem;
  height: ${(props) => (props.$small ? "8.2rem" : "13.7rem")};
  background-color: #fe5f55;
`;

export const StepTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.8rem;
`;
export const StepText = styled.span`
  color: #4d4d4d;
  max-width: 51.6rem;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  margin-top: ${(props) => (props.$marginTop ? "3.4rem" : "0rem")};
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 61.2rem;
  width: 100%;
`;

export const HowItWorksImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
`;

export const HowItWorksImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 10;

  align-items: center;
  gap: 2rem;
  @media (max-width: 1030px) {
    scale: 0.8;
  }

  @media (max-width: 770px) {
    scale: 0.65;
    margin: -5rem 0rem;
  }
  @media (max-width: 620px) {
    scale: 0.55;
  }
  @media (max-width: 522px) {
    scale: 0.45;
    margin: -10rem 0rem;
  }
  @media (max-width: 422px) {
    scale: 0.35;
  }
`;
export const ImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;
export const Img1 = styled.img`
  width: 35.65rem;
  height: 30.2rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
`;
export const Img2 = styled.img`
  width: 16.97rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 19rem;
  border-radius: 1rem;
  object-fit: cover;
`;

export const Img3 = styled.img`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  width: 16.97rem;
  height: 19rem;
  border-radius: 1rem;
  object-fit: cover;
`;
export const Img4 = styled.img`
  width: 28.56rem;
  height: 10.1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
`;
export const Img5 = styled.img`
  width: 28.56rem;
  height: 24.59rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
`;
export const Img6 = styled.img`
  width: 28.56rem;
  height: 12.78rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
`;
export const Img7 = styled.img`
  height: 33.5rem;
  width: 28.56rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
`;
export const Img8 = styled.img`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 15.68rem;
  width: 28.56rem;
  border-radius: 1rem;
  object-fit: cover;
`;

export const OurJourneyRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
  align-items: center;
  gap: 3rem;
  margin-bottom: ${(props) => (props.$marginBottom ? "8rem" : "0rem")};
  @media (max-width: 1340px) {
    flex-direction: column;
    margin-top: ${(props) => (props.$reverse ? "2rem" : "0rem")};
    gap: 0px;
  }
`;

export const OurJourneyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1rem;
  padding: 4rem 3rem;
  max-width: ${(props) => (props.$maxWidth ? "57.3rem" : "69.4rem")};
  height: ${(props) => (props.$maxWidth ? "37.1rem" : "unset")};

  background: ${(props) => (props.$reverse ? "#EBEBF4" : "#ffe5e4")};
  @media (max-width: 1340px) {
    margin-top: ${(props) => (props.$reverse ? "2rem" : "0rem")};
  }
  @media (max-width: 580px) {
    height: fit-content;
  }
`;

export const OurJourneyText = styled.span`
  color: #646464;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 3rem; /* 166.667% */
  letter-spacing: -0.36px;
`;

export const OurJourneyTitle = styled.span`
  color: ${(props) => (props.$red ? "#FE5F55" : "#212121")};

  font-family: "Proxima-Nova-Bold";
  font-size: 2.8rem;
  line-height: 5.2rem;
  letter-spacing: -0.56px;
`;
export const OurJourneyImg1 = styled.img`
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35));
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;

  @media (max-width: 1340px) {
    width: 70%;
    height: auto;
  }
`;

export const MissionAndVisionText = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const MissionAndVisionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @media (max-width: 1250px) {
    margin-top: 2rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 535px) {
    scale: 0.9;
    margin-top: 0;
  }
  @media (max-width: 485px) {
    scale: 0.75;
  }
  @media (max-width: 430px) {
    scale: 0.6;
  }
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const MissionAndVisionColum = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 58.7rem;
`;

export const MissionAndVisionImg1 = styled.img`
  height: 25.3rem;
  border-radius: 1rem;
  width: 26.9rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  object-fit: cover;
`;
export const MissionAndVisionImg2 = styled.img`
  height: 33.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  width: 26.9rem;
  border-radius: 1rem;
  object-fit: cover;
`;
export const MissionAndVisionImg4 = styled.img`
  height: 25.3rem;
  width: 26.9rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
`;
export const MissionAndVisionImg3 = styled.img`
  height: 33.3rem;
  width: 26.9rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 1rem;
  object-fit: cover;
`;

export const OurMissionIcon = styled(OurMission)`
  min-width: 5.2rem;
  min-height: 5.2rem;
`;
export const OurVisionIcon = styled(OurVision)`
  min-width: 5.2rem;
  min-height: 5.2rem;
`;

export const TheEyCrowdPromiseImg1 = styled.img`
  width: 24.8rem;
  border-radius: 1rem;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 44.4rem;
  @media (max-width: 547px) {
    height: auto;
    width: 50%;
  }
`;
export const TheEyCrowdPromiseImg2 = styled.img`
  width: 51.5rem;
  border-radius: 1rem;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  height: 23.2rem;
  @media (max-width: 547px) {
    width: 100%;
    height: auto;
  }
`;

export const Achievements = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  margin-top: 5.5rem;
  width: 100%;
`;

export const AchievementsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$flexStart ? "flex-start" : "center")};
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: ${(props) => (props.$marginTop ? "2rem" : "0rem")};
`;

export const AchievementsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$center ? "center" : "flex-start")};
  gap: ${(props) => (props.$offGap ? "0rem" : "2rem")};
`;

export const BlueDotIcon = styled(BlueDot)``;
export const Line = styled.div`
  width: 0.1rem;
  height: ${(props) => (props.$small ? "33rem" : "38rem")};
  background: rgba(0, 0, 0, 0.22);
  @media (max-width: 522px) {
    height: ${(props) => (props.$small ? "35rem" : "38rem")};
  }
  @media (max-width: 481px) {
    height: ${(props) => (props.$small ? "35rem" : "41rem")};
  }
  @media (max-width: 478px) {
    height: ${(props) => (props.$small ? "35rem" : "44rem")};
  }
  @media (max-width: 447px) {
    height: ${(props) => (props.$small ? "38rem" : "44rem")};
  }

  @media (max-width: 348px) {
    height: ${(props) => (props.$small ? "38rem" : "47rem")};
  }
  @media (max-width: 333px) {
    height: ${(props) => (props.$small ? "41rem" : "47rem")};
  }
  @media (max-width: 328px) {
    height: ${(props) => (props.$small ? "41rem" : "50rem")};
  }
  @media (max-width: 309px) {
    height: ${(props) => (props.$small ? "44rem" : "50rem")};
  }
  @media (max-width: 306px) {
    height: ${(props) => (props.$small ? "44rem" : "53rem")};
  }
`;

export const AchievementLabel = styled.span`
  color: #0a0a0a;
  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
  line-height: 3rem; /* 166.667% */
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.4rem;
  border-radius: 0.4rem;
  max-height: 3.5rem;
  background: linear-gradient(
    90deg,
    rgba(46, 49, 144, 0.2) 0.03%,
    rgba(0, 16, 28, 0) 84.83%
  );
`;
export const AchievementText = styled.span`
  color: #666;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.5rem;
  line-height: 3rem; /* 200% */
  max-width: 55.1rem;
  margin-bottom: 2rem;
  margin-top: -1rem;
`;
