import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { GoToTop } from "./screens/HomePage/HomePage.styles";
import GlobalStyles from "./styles/globalStyles";

import { ReactComponent as ChevronTop } from "./assets/icons/chevron-top.svg";
import SplashScreen from "./components/SplashScreen/SplashScreen";

import HomePage from "./screens/HomePage/HomePage";

import ContactUsPage from "./screens/ContactUsPage/ContactUsPage";
import SupportPage from "./screens/SupportPage/SupportPage";
import CookiesPolicyPage from "./screens/CookiesPolicyPage/CookiesPolicyPage";
import TermsAndConditionsPage from "./screens/TermsAndConditionsPage/TermsAndConditionsPage";
import EULAPage from "./screens/EULAPage/EULAPage";
import DisclaimerPage from "./screens/DisclaimerPage/DisclaimerPage";
import PrivacyPolicyPage from "./screens/PrivacyPolicyPage/PrivacyPolicyPage";
import BusinessFAQsPage from "./screens/BusinessFAQsPage/BusinessFAQsPage";
import PricingPage from "./screens/PricingPage/PricingPage";
import AboutPage from "./screens/AboutPage/AboutPage";
import OurTeamPage from "./screens/OurTeamPage/OurTeamPage";
import CaseStudyPage from "./screens/CaseStudyPage/CaseStudyPage";
import EmagazinePage from "./screens/EmagazinePage/EmagazinePage";
import CasesPage from "./screens/CasesPage/CasesPage";
import EmbedPage from "./screens/EmbedPage/EmbedPage";

function App() {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 2500);
    return () => clearTimeout(timer);
  }, []);

  const [showGoToTop, setShowGoToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowGoToTop(true);
    } else {
      setShowGoToTop(false);
    }
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <GlobalStyles />

      {minimumDurationPassed ? (
        <>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/about" element={<AboutPage />} />
            {/* <Route path="/team" element={<OurTeamPage />} /> */}
            <Route path="/case" element={<CaseStudyPage />} />
            <Route path="/e-magazine" element={<EmagazinePage />} />
            <Route path="/cases" element={<CasesPage />} />
            <Route path="/ST25" element={<EmbedPage />} />

            {/* <Route path="/connect" element={<ContactUsPage />} /> */}
            <Route path="/supportrequest" element={<SupportPage />} />
            <Route path="/business-faqs" element={<BusinessFAQsPage />} />
            <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/eula" element={<EULAPage />} />
            {/* <Route path="/disclaimer" element={<DisclaimerPage />} /> */}
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            {/* <Route path="/data-subject-access-request" element={<DSARPage />} /> */}
          </Routes>
          {/* <GoToTop $show={showGoToTop} onClick={goToTop}>
            <ChevronTop />
          </GoToTop> */}
        </>
      ) : (
        <SplashScreen />
      )}
    </Fragment>
  );
}

export default App;
