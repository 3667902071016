import React, { useState } from "react";
import {
  BoldText,
  ByEyc,
  ConnectContainer,
  IconColumn,
  IconContainer,
  IconRow,
  IconText,
  IconTitle,
  Image,
  ImageRow,
  Info,
  InfoContainer,
  Label2,
  LabelContainer,
  Logo,
  Number,
  OrangeCircle,
  OurUserPhoto,
  OurUserPhotoDiv,
  OurUserSectionRow,
  Photo,
  PhotoColumn,
  PhotoLong,
  PhotoLongWidth,
  PhotoRow,
  PhotoShort,
  RedLine,
  Section,
  SectionColum,
  SectionColumn,
  SectionSubtitle,
  SectionTitle,
  SectionTitleColumn,
  SectionTitleRow,
  Text,
  TextColumn,
  TextRow,
  YellowCircle,
} from "./CaseStudyPage.styles";

import Img1 from "../../assets/images/redesign/image42.jpeg";
import Img2 from "../../assets/images/redesign/image43.jpeg";
import Img3 from "../../assets/images/redesign/image44.jpeg";
import Img4 from "../../assets/images/redesign/image45.jpeg";
import Img5 from "../../assets/images/redesign/image46.jpeg";
import Img6 from "../../assets/images/redesign/image47.jpeg";
import Img7 from "../../assets/images/redesign/image48.jpeg";
import Img8 from "../../assets/images/redesign/image49.png";

import { ReactComponent as ProblemIcon } from "../../assets/icons/problem.svg";
import { ReactComponent as SolutionIcon } from "../../assets/icons/solution.svg";
import { ReactComponent as TicketIcon } from "../../assets/icons/ticket.svg";
import { ReactComponent as SaleIcon } from "../../assets/icons/sale.svg";
import { ReactComponent as VisitorsIcon } from "../../assets/icons/visitors.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";
import { ReactComponent as LogoipsumIcon } from "../../assets/icons/logoipsum.svg";

import Button from "../../components/utils/Button/Button";

const CaseStudyPageSections = {
  HeroSection: () => (
    <Section>
      <Logo />
      <SectionTitleColumn>
        <SectionTitle>
          <SectionTitle $red>Bakery Success -</SectionTitle> Boosting Sales with{" "}
          <SectionTitle $red>Targeted Customer </SectionTitle>
          Traffic
        </SectionTitle>

        <SectionSubtitle>
          A local bakery faced low foot traffic, especially during slow hours.
          With 50 new customers weekly from EyCrowd, they saw a 265% sales
          boost, a $24 average ticket value, and an 81% purchase rate, turning
          quiet times into profit.
        </SectionSubtitle>
        <ByEyc />
      </SectionTitleColumn>
      <PhotoRow>
        <PhotoLong src={Img1} />
        <PhotoColumn>
          <PhotoShort src={Img2} />
          <PhotoShort src={Img3} />
        </PhotoColumn>
      </PhotoRow>
    </Section>
  ),
  ProblemSolutionSection: () => (
    <Section $white style={{ overflow: "visible" }}>
      <YellowCircle />
      <OrangeCircle $left />
      <SectionColumn>
        <Photo src={Img5} />
        <IconContainer>
          <SolutionIcon />
        </IconContainer>

        <SectionTitle $small>
          The{" "}
          <SectionTitle $small $red>
            Solution
          </SectionTitle>
        </SectionTitle>

        <SectionSubtitle style={{ marginTop: -20, marginBottom: 0 }}>
          Imagine welcoming 50 new customers every week, especially during slow
          periods. Our proven strategies help fill your store with fresh faces
          when you need it the most. Boost your sales, increase foot traffic,
          and keep your business thriving. No more empty hours — just consistent
          growth. Let us bring new customers to your door!
        </SectionSubtitle>
      </SectionColumn>

      <SectionColumn>
        <IconContainer>
          <ProblemIcon />
        </IconContainer>

        <SectionTitle $small>
          The{" "}
          <SectionTitle $small $red>
            Problem
          </SectionTitle>
        </SectionTitle>

        <SectionSubtitle style={{ marginTop: -20, marginBottom: 0 }}>
          Facing slow weekday mornings even with amazing products? Don't let
          quiet hours affect your business growth. Our strategic approach helps
          drive consistent foot traffic during off-peak times. By targeting the
          right audience, we ensure your products get the attention they
          deserve. Turn slow mornings into busy, profitable days!
        </SectionSubtitle>
        <Photo src={Img4} />
      </SectionColumn>
    </Section>
  ),
  SmartSchedulingSection: () => (
    <SectionColum style={{ padding: "100px 120px" }}>
      <SectionTitleColumn>
        <SectionTitle>
          Smart Scheduling for <SectionTitle $red>Maximum Growth</SectionTitle>
        </SectionTitle>

        <SectionSubtitle style={{ maxWidth: "100%" }}>
          Smart scheduling ensures steady customer flow by directing visits
          during off-peak hours. This strategy maximizes sales opportunities,
          reduces downtime, and keeps business thriving effortlessly.
        </SectionSubtitle>
      </SectionTitleColumn>
      <PhotoLongWidth src={Img6} />

      <TextRow>
        <TextColumn>
          <IconContainer style={{ marginBottom: 0 }}>
            <Number>01</Number>
          </IconContainer>

          <Text>
            <Text $bold>Scheduled Visits: </Text>
            Scheduled Visits ensured a steady flow of customers by directing
            them to visit during off-peak hours. This strategy helped balance
            foot traffic, preventing rush-hour bottlenecks. As a result, the
            bakery maintained efficient operations while boosting sales.
          </Text>
        </TextColumn>
        <TextColumn>
          <IconContainer style={{ marginBottom: 0 }}>
            <Number>02</Number>
          </IconContainer>

          <Text>
            <Text $bold>Word-of-Mouth Marketing: </Text>
            Word-of-Mouth Marketing encouraged customers to share their
            experience, increasing organic reach. Happy customers became
            advocates, attracting more visitors.
          </Text>
        </TextColumn>
      </TextRow>
      <TextColumn>
        <IconContainer style={{ marginBottom: 0 }}>
          <Number>03</Number>
        </IconContainer>

        <Text>
          <Text $bold>Incentive Strategy: </Text>
          Incentive Strategy offered exclusive promotions to first-time buyers,
          making their initial visit more rewarding. These special deals
          encouraged them to return, increasing customer retention. Over time,
          new visitors transformed into loyal regulars, boosting long-term
          sales.
        </Text>
      </TextColumn>
    </SectionColum>
  ),
  HigherSalesSection: () => (
    <SectionColum $center $white>
      <SectionTitleColumn style={{ alignItems: "center" }}>
        <LabelContainer>
          <RedLine />
          <Label2 $light>Results</Label2>
          <RedLine />
        </LabelContainer>

        <SectionTitle $center>
          Higher Sales, <SectionTitle $red>New Customers,</SectionTitle> Lasting
          Impact!
        </SectionTitle>
      </SectionTitleColumn>
      <ImageRow>
        <Image src={Img7} />
        <TextColumn>
          <IconRow>
            <SaleIcon />
            <IconTitle>Increase Sales</IconTitle>
          </IconRow>
          <Text $maxWidth>
            The bakery experienced a 265% increase in sales, significantly
            boosting revenue. Strategic customer scheduling and promotions
            helped drive consistent growth.
          </Text>
          <IconRow>
            <TicketIcon />
            <IconTitle>Average Ticket</IconTitle>
          </IconRow>
          <Text $maxWidth>
            The bakery achieved an average ticket value of $24, increasing
            overall revenue per customer. Effective promotions and customer
            engagement encouraged higher spending.
          </Text>
          <IconRow>
            <VisitorsIcon />
            <IconTitle>New Visitors</IconTitle>
          </IconRow>
          <Text $maxWidth>
            81% of new visitors made a purchase, proving the success of targeted
            customer traffic. Engaging experiences and exclusive promotions
            encouraged first-time buyers to convert.
          </Text>
        </TextColumn>
      </ImageRow>
    </SectionColum>
  ),
  OurUserKindSection: () => (
    <SectionColum $center $small>
      <SectionTitleColumn style={{ alignItems: "center", marginBottom: -140 }}>
        <LabelContainer>
          <RedLine />
          <Label2>Testimonials</Label2>
          <RedLine />
        </LabelContainer>

        <SectionTitle>
          Our User <SectionTitle $red>Kind Words</SectionTitle>
        </SectionTitle>
      </SectionTitleColumn>

      <OurUserSectionRow>
        <OurUserPhotoDiv>
          <OurUserPhoto src={Img8} />
          <InfoContainer>
            <Info $bold>Business Owner</Info>
            <Info>Regional Retail Bakery</Info>
          </InfoContainer>
        </OurUserPhotoDiv>
        <ConnectContainer>
          <LogoipsumIcon />
          <IconRow $maxWidth>
            <LocationIcon />
            <IconColumn>
              <IconText $small>Shop Location</IconText>
              <IconText>123, Lorem Street, New York</IconText>
            </IconColumn>
          </IconRow>
          <IconRow $maxWidth>
            <MailIcon />
            <IconColumn style={{ marginTop: -4 }}>
              <IconText $small>Send Mail</IconText>
              <IconText>example@gmail.com</IconText>
            </IconColumn>
          </IconRow>
        </ConnectContainer>
        <BoldText>
          “EyCrowd brings us 50 new customers every week, like clockwork. It's
          been a total game-changer for boosting our sales right when we need
          it.”
        </BoldText>
      </OurUserSectionRow>
    </SectionColum>
  ),
};

export default CaseStudyPageSections;
