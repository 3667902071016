import React from "react";
import {
  Section,
  SectionTitle,
  SectionTitleRow,
  BgRed,
  ImagesContainer,
  ImagesRow,
  Img1,
  ImagesColumn,
  Img2,
  Img3,
  Img8,
  Img7,
  Img6,
  Img5,
  Img4,
  YellowCircleTopRight,
  OrangeCircleLeft,
  OurJourneyRow,
  OurJourneyImg1,
  OurJourneyTextContainer,
  OurJourneyTitle,
  OurJourneyText,
  MissionAndVisionText,
  MissionAndVisionColum,
  MissionAndVisionRow,
  MissionAndVisionImg1,
  MissionAndVisionImg2,
  MissionAndVisionImg3,
  MissionAndVisionImg4,
  SectionSubtitle,
  OurMissionIcon,
  OurVisionIcon,
  TheEyCrowdPromiseImg1,
  TheEyCrowdPromiseImg2,
  Achievements,
  BlueDotIcon,
  Line,
  AchievementsColumn,
  AchievementsRow,
  AchievementLabel,
  AchievementText,
  BgRed2,
  Row,
  Column,
  StyledRow,
} from "./AboutPage.styles";

import Image1 from "../../assets/images/redesign/image23.jpeg";
import Image1a from "../../assets/images/redesign/image51.jpg";
import Image2 from "../../assets/images/redesign/image24.jpeg";
import Image2a from "../../assets/images/redesign/image50.jpg";
import Image3 from "../../assets/images/redesign/image25.jpeg";
import Image4 from "../../assets/images/redesign/image26.jpeg";
import Image5 from "../../assets/images/redesign/image27.jpeg";
import Image6 from "../../assets/images/redesign/image28.png";
import Image7 from "../../assets/images/redesign/image29.jpeg";
import Image8 from "../../assets/images/redesign/image30.png";
import Image9 from "../../assets/images/redesign/image31.png";
import Image10 from "../../assets/images/redesign/image32.png";
import Image10a from "../../assets/images/redesign/image52.jpg";
import Image11 from "../../assets/images/redesign/image33.jpeg";
import Image12 from "../../assets/images/redesign/image34.jpeg";
import Image13 from "../../assets/images/redesign/image35.jpeg";
import Image14 from "../../assets/images/redesign/image36.jpeg";
import Image15 from "../../assets/images/redesign/image37.jpeg";
import Image16 from "../../assets/images/redesign/image38.jpeg";
import Image17 from "../../assets/images/redesign/image39.png";
import Image58 from "../../assets/images/redesign/image58.png";

import {
  FeatureCard,
  FeatureIcon1,
  FeatureIcon2,
  FeatureIcon3,
  FeatureIcon4,
  FeaturesContainer,
  FeatureText,
  FeatureTitle,
} from "../HomePage/HomePage.styles";
import ToggleSwitch from "../../components/utils/ToggleSwitch";

const AboutPageSections = {
  HeroSection: () => (
    <>
      <Section
        $alignItemsCenter
        $column
        $firstSection
        style={{ minHeight: "100%" }}
      >
        <BgRed />
        <BgRed2 />
        {/* <ToggleSwitch /> */}
        <SectionTitleRow $offGap>
          <SectionTitle>
            Genuine Voices. <SectionTitle $red>Real</SectionTitle>
          </SectionTitle>
        </SectionTitleRow>

        <SectionTitleRow $offGap $marginTopOff>
          <SectionTitle>
            <SectionTitle $red>Connections. </SectionTitle>
            Authentic <SectionTitle $red>Marketing.</SectionTitle>
          </SectionTitle>
        </SectionTitleRow>
      </Section>

      <ImagesContainer>
        <ImagesRow>
          <ImagesColumn>
            <Img1 src={Image1a} alt="EyCrowd - Photo" />
            <ImagesRow>
              <Img2 src={Image2a} alt="EyCrowd - Photo" />
              <Img3 src={Image3} alt="EyCrowd - Photo" />
            </ImagesRow>
          </ImagesColumn>
          <ImagesColumn>
            <Img4 src={Image4} alt="EyCrowd - Photo" />
            <Img5 src={Image5} alt="EyCrowd - Photo" />
            <Img6 src={Image58} alt="EyCrowd - Photo" />
          </ImagesColumn>
          <ImagesColumn>
            <Img7 src={Image7} alt="EyCrowd - Photo" />
            <Img8 src={Image8} alt="EyCrowd - Photo" />
          </ImagesColumn>
        </ImagesRow>
      </ImagesContainer>
    </>
  ),
  OurJourneySection: () => (
    <Section $alignItemsCenter $column>
      <YellowCircleTopRight />
      <OrangeCircleLeft />

      <OurJourneyRow>
        <OurJourneyImg1 src={Image9} alt="EyCrowd - Photo" />
        <OurJourneyTextContainer>
          <OurJourneyTitle>
            Our <OurJourneyTitle $red>Journey</OurJourneyTitle>
          </OurJourneyTitle>
          <OurJourneyText>
            Inspired in 2019 by Ayman Al-Zamil and Brad Cowdrey, EyCrowd was
            born from a groundbreaking idea—to empower everyday people to
            champion the brands they love. Our mission was clear: create a
            platform where people could share their authentic voices, connect
            with like-minded individuals, and showcase their favorite products
            and services through real, meaningful experiences.
          </OurJourneyText>
          <OurJourneyText>
            Today, EyCrowd is redefining the marketing landscape. We've created
            the world's first brand experience marketing platform to bring new
            customers to try your products and capture UGC of their experience.
            Through app-guided, interactive campaigns—both online and in
            person—we enable companies to drive on-demand foot traffic with
            pay-per-visitor pricing
          </OurJourneyText>
        </OurJourneyTextContainer>
      </OurJourneyRow>

      <OurJourneyRow $reverse>
        <OurJourneyImg1 src={Image10a} alt="EyCrowd - Photo" />
        <OurJourneyTextContainer $reverse>
          <OurJourneyText>
            Our innovative approach allows businesses to attract real customers
            who not only try their products but also create genuine
            user-generated content (UGC) like photos, videos, and testimonials.
            With EyCrowd, businesses can generate foot-traffic-on-demand and
            engagement seamlessly through our 100% done-for-you service. We
            deliver guaranteed results with measurable impact, helping
            businesses capture authentic UGC that connects and resonates. .
          </OurJourneyText>
          <OurJourneyText>
            EyCrowd brings your brand to life by driving new customers to
            experience your products while capturing the UGC that makes a
            lasting impression.
          </OurJourneyText>
        </OurJourneyTextContainer>
      </OurJourneyRow>
    </Section>
  ),
  MissionAndVisionSection: () => (
    <Section $alignItemsCenter $red>
      <MissionAndVisionColum>
        <SectionTitleRow>
          <SectionTitle>Mission</SectionTitle>
          <SectionTitle $red>And</SectionTitle>
          <SectionTitle>Vision</SectionTitle>
        </SectionTitleRow>
        <MissionAndVisionText>
          At EyCrowd, we are redefining marketing by prioritizing authenticity,
          human connection, shared values, and meaningful engagement at every
          stage. <br />
          We believe true promotion and word-of-mouth stems from genuine human
          experiences. That's why we are dedicated to bringing authentic
          marketing to people from all walks of life. By embracing humanity, we
          craft experience that truly reflect the values, stories, and unique
          identities of the communities across the globe. Whether it's capturing
          everyday moments or amplifying diverse perspectives, our approach
          ensures every voice is heard, and every story matters.
          <br /> For us, authenticity isn't just a strategy—it's the foundation
          of everything we do.
        </MissionAndVisionText>
      </MissionAndVisionColum>

      <MissionAndVisionRow>
        <MissionAndVisionColum>
          <MissionAndVisionImg1 src={Image11} alt="EyCrowd - Photo" />
          <MissionAndVisionImg2 src={Image12} alt="EyCrowd - Photo" />
        </MissionAndVisionColum>
        <MissionAndVisionColum>
          <MissionAndVisionImg3 src={Image13} alt="EyCrowd - Photo" />
          <MissionAndVisionImg4 src={Image14} alt="EyCrowd - Photo" />
        </MissionAndVisionColum>
      </MissionAndVisionRow>
    </Section>
  ),
  KeyFeaturesSection: () => (
    <Section $column $alignItemsCenter>
      <OurJourneyRow $marginBottom>
        <OurJourneyTextContainer $reverse $maxWidth>
          <OurMissionIcon />
          <OurJourneyTitle>
            Our <OurJourneyTitle $red>Mission</OurJourneyTitle>
          </OurJourneyTitle>
          <OurJourneyText>
            We help businesses attract real customers to experience their
            products and services wherever they're offered—while amplifying
            their reach through genuine user-generated content. EyCrowd enables
            brands to foster direct, meaningful interactions that convert
            curious prospects into loyal fans.
          </OurJourneyText>
        </OurJourneyTextContainer>
        <OurJourneyTextContainer $reverse $maxWidth>
          <OurVisionIcon />
          <OurJourneyTitle>
            Our <OurJourneyTitle $red>Vision</OurJourneyTitle>
          </OurJourneyTitle>
          <OurJourneyText>
            We envision a world where marketing feels less like noise and more
            like a conversation. By uniting brands and people through authentic
            experiences, EyCrowd is building a more connected, genuine, and
            impactful way for businesses to grow and thrive.
          </OurJourneyText>
        </OurJourneyTextContainer>
      </OurJourneyRow>
      <SectionTitleRow>
        <SectionTitle>Why</SectionTitle>
        <SectionTitle $red>EyCrowd is Different</SectionTitle>
      </SectionTitleRow>
      <SectionSubtitle $big>
        EyCrowd bridges the gap between traditional marketing strategies and
        personal connections, in-person and online. With EyCrowd, businesses
        aren't just running campaigns—they're creating fun, interactive brand
        <br />
        events that customers are eager to be part of.
      </SectionSubtitle>
      <SectionTitleRow style={{ marginTop: 80 }}>
        <SectionTitle>Key</SectionTitle>
        <SectionTitle $red>Features</SectionTitle>
      </SectionTitleRow>
      <FeaturesContainer>
        <FeatureCard>
          <FeatureIcon2 />
          <FeatureTitle>Pay-Per-Visitor Pricing</FeatureTitle>
          <FeatureText>
            You only pay for results you can see and measure. Whether it's foot
            traffic, online engagement, or in-store visits, we deliver
            guaranteed outcomes.
          </FeatureText>
        </FeatureCard>

        <FeatureCard>
          <FeatureIcon3 />
          <FeatureTitle>Authentic UGC</FeatureTitle>
          <FeatureText>
            We capture real photos, videos, and testimonials of your customers
            interacting with your brand—boosting credibility and ensuring
            authenticity.
          </FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon1 />
          <FeatureTitle>100% Done-for-You Service</FeatureTitle>
          <FeatureText>
            We handle everything, so you can focus on running your business and
            delighting your customers.
          </FeatureText>
        </FeatureCard>

        <FeatureCard>
          <FeatureIcon4 />
          <FeatureTitle>Scalability</FeatureTitle>
          <FeatureText>
            Whether you're a local business or a global brand, our platform is
            built to grow with your needs.
          </FeatureText>
        </FeatureCard>
      </FeaturesContainer>
    </Section>
  ),

  GroundbreakingImpactSection: () => (
    <Section $red $column $alignItemsCenter $padding>
      <SectionTitleRow>
        <SectionTitle>A Track Record of</SectionTitle>
        <SectionTitle $red>Groundbreaking Impact</SectionTitle>
      </SectionTitleRow>
      <SectionSubtitle $big>
        Since launching, EyCrowd has made extraordinary strides in redefining
        what marketing experiences can achieve.
      </SectionSubtitle>
      <Achievements>
        <AchievementsColumn>
          <SectionTitle $small>
            Milestones and{" "}
            <SectionTitle $small $red>
              Achievements
            </SectionTitle>
          </SectionTitle>
          <AchievementsRow $flexStart $marginTop>
            <AchievementsRow $flexStart style={{ flexWrap: "nowrap" }}>
              <AchievementsColumn $offGap $center>
                <Line />
              </AchievementsColumn>
              <AchievementsColumn>
                <AchievementsColumn style={{ marginTop: -9 }}>
                  <Row>
                    <BlueDotIcon />
                    <AchievementLabel>
                      Foundation and Prototyping
                    </AchievementLabel>
                  </Row>
                  <AchievementText>
                    EyCrowd was established, and we unveiled our first prototype
                    in 2021—an innovation that sparked widespread excitement and
                    laid the groundwork for our transformative approach to
                    marketing.
                  </AchievementText>
                </AchievementsColumn>
                <AchievementsColumn>
                  <Row>
                    <BlueDotIcon />
                    <AchievementLabel>
                      Experimentation and Growth
                    </AchievementLabel>
                  </Row>

                  <AchievementText>
                    A year of rapid experimentation, we partnered with eyVocates
                    and brands nationwide to refine our platform. These
                    collaborations demonstrated its ability to deliver dynamic,
                    engaging marketing campaigns while significantly expanding
                    our reach and impact.
                  </AchievementText>
                </AchievementsColumn>
                <AchievementsColumn>
                  <Row>
                    <BlueDotIcon />
                    <AchievementLabel>
                      Extensive Market Testing
                    </AchievementLabel>
                  </Row>
                  <AchievementText>
                    Engaged over 3,000 professional marketing executives,
                    earning an outstanding 99% positive reception and validating
                    our platform's potential.
                  </AchievementText>
                </AchievementsColumn>
              </AchievementsColumn>
            </AchievementsRow>
            <AchievementsRow $flexStart style={{ flexWrap: "nowrap" }}>
              <AchievementsColumn $offGap $center>
                <Line $small />
              </AchievementsColumn>
              <AchievementsColumn>
                <AchievementsColumn style={{ marginTop: -9 }}>
                  <Row>
                    <BlueDotIcon />
                    <AchievementLabel>Strategic Engagement</AchievementLabel>
                  </Row>
                  <AchievementText>
                    Held over 800 impactful engagements with marketing leaders,
                    with many recognizing EyCrowd as a groundbreaking solution
                    for the industry.
                  </AchievementText>
                </AchievementsColumn>
                <AchievementsColumn>
                  <Row>
                    <BlueDotIcon />
                    <AchievementLabel>Ongoing Innovation</AchievementLabel>
                  </Row>
                  <AchievementText>
                    Introduced hundreds of advanced capabilities, including EyLa
                    AI and the Campaign Experience Builder, empowering
                    businesses to boost engagement and streamline operations.
                  </AchievementText>
                </AchievementsColumn>
                <AchievementsColumn>
                  <Row>
                    <BlueDotIcon />
                    <AchievementLabel>Outreach and Impact</AchievementLabel>
                  </Row>
                  <AchievementText>
                    Responding to growing demand, we've enhanced our educational
                    resources, tools, and client support to help partners fully
                    leverage our platform's potential.
                  </AchievementText>
                </AchievementsColumn>
              </AchievementsColumn>
            </AchievementsRow>
          </AchievementsRow>
        </AchievementsColumn>
      </Achievements>
    </Section>
  ),
  TheEyCrowdPromiseSection: () => (
    <Section $alignItemsCenter style={{ gap: 20 }}>
      <Column>
        <StyledRow>
          <TheEyCrowdPromiseImg1 src={Image15} alt="EyCrowd - Photo" />
          <TheEyCrowdPromiseImg1 src={Image16} alt="EyCrowd - Photo" />
        </StyledRow>
        <TheEyCrowdPromiseImg2 src={Image17} alt="EyCrowd - Photo" />
      </Column>

      <MissionAndVisionColum>
        <SectionTitleRow>
          <SectionTitle>The</SectionTitle>
          <SectionTitle $red>EyCrowd Promise</SectionTitle>
        </SectionTitleRow>
        <MissionAndVisionText>
          We're not just delivering marketing and advertising; we're changing
          the way brands and people connect in-person and online. Every business
          decision we make is guided by our commitment to authenticity, trust,
          innovation, and mutual respect.
          <br />
          <br />
          Starting with an idea—that brands should be championed by everyday
          people—EyCrowd has grown into a global movement driven by genuine
          experiences and visible results. Ready to bring your brand to life?
          EyCrowd makes it easy to connect with new customers through authentic
          storytelling and targeted engagement, helping them discover your brand
          and try your products firsthand.
        </MissionAndVisionText>
      </MissionAndVisionColum>
    </Section>
  ),
};

export default AboutPageSections;
