import React, { useEffect } from "react";

import { FadeIn } from "./PricingPage.styles";

import PricingPageSections from "./PricingPageSections";
import MainContainer from "../../components/MainContainer/MainContainer";

const { HeroSection, MonthlySection, CustomPackageSection, HowItWorksSection } =
  PricingPageSections;

const PricingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <HeroSection />
        <MonthlySection />
        {/* <CustomPackageSection /> */}
        <HowItWorksSection />
      </FadeIn>
    </MainContainer>
  );
};

export default PricingPage;
