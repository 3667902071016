import React, { useEffect } from "react";

import {
  BgRed,
  Case,
  CaseDate,
  CaseImg,
  CaseInfo,
  Cases,
  CaseSubtitle,
  CaseTitle,
  Content,
  FadeIn,
  SectionTitle,
} from "./CasesPage.styles";

import CasePhoto from "../../assets/images/redesign/image45.jpeg";
import MainContainer from "../../components/MainContainer/MainContainer";
import Button from "../../components/utils/Button/Button";
import { formatDate } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const CasesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <MainContainer>
      <FadeIn>
        <Content>
          <BgRed />

          <SectionTitle>
            Our <SectionTitle $red>Case Studies</SectionTitle>
          </SectionTitle>

          <Cases>
            <Case>
              <CaseImg src={CasePhoto} />
              <CaseInfo>
                <CaseDate>{formatDate(new Date())}</CaseDate>
                <CaseTitle>
                  <CaseTitle $red>Bakery Success -</CaseTitle> Boosting Sales
                  with <CaseTitle $red>Targeted Customer </CaseTitle>
                  Traffic
                </CaseTitle>

                <CaseSubtitle>
                  A local bakery faced low foot traffic, especially during slow
                  hours. With 50 new customers weekly from EyCrowd, they saw a
                  265% sales boost, a $24 average ticket value, and an 81%
                  purchase rate, turning quiet times into profit.
                </CaseSubtitle>
                <Button title={"Read more"} onClick={() => navigate("/case")} />
              </CaseInfo>
            </Case>
            <Case>
              <CaseImg src={CasePhoto} />
              <CaseInfo>
                <CaseDate>{formatDate(new Date())}</CaseDate>
                <CaseTitle>
                  <CaseTitle $red>Bakery Success -</CaseTitle> Boosting Sales
                  with <CaseTitle $red>Targeted Customer </CaseTitle>
                  Traffic
                </CaseTitle>

                <CaseSubtitle>
                  A local bakery faced low foot traffic, especially during slow
                  hours. With 50 new customers weekly from EyCrowd, they saw a
                  265% sales boost, a $24 average ticket value, and an 81%
                  purchase rate, turning quiet times into profit.
                </CaseSubtitle>
                <Button title={"Read more"} onClick={() => navigate("/case")} />
              </CaseInfo>
            </Case>
            <Case>
              <CaseImg src={CasePhoto} />
              <CaseInfo>
                <CaseDate>{formatDate(new Date())}</CaseDate>
                <CaseTitle>
                  <CaseTitle $red>Bakery Success -</CaseTitle> Boosting Sales
                  with <CaseTitle $red>Targeted Customer </CaseTitle>
                  Traffic
                </CaseTitle>

                <CaseSubtitle>
                  A local bakery faced low foot traffic, especially during slow
                  hours. With 50 new customers weekly from EyCrowd, they saw a
                  265% sales boost, a $24 average ticket value, and an 81%
                  purchase rate, turning quiet times into profit.
                </CaseSubtitle>
                <Button title={"Read more"} onClick={() => navigate("/case")} />
              </CaseInfo>
            </Case>
            <Case>
              <CaseImg src={CasePhoto} />
              <CaseInfo>
                <CaseDate>{formatDate(new Date())}</CaseDate>
                <CaseTitle>
                  <CaseTitle $red>Bakery Success -</CaseTitle> Boosting Sales
                  with <CaseTitle $red>Targeted Customer </CaseTitle>
                  Traffic
                </CaseTitle>

                <CaseSubtitle>
                  A local bakery faced low foot traffic, especially during slow
                  hours. With 50 new customers weekly from EyCrowd, they saw a
                  265% sales boost, a $24 average ticket value, and an 81%
                  purchase rate, turning quiet times into profit.
                </CaseSubtitle>
                <Button title={"Read more"} onClick={() => navigate("/case")} />
              </CaseInfo>
            </Case>
          </Cases>
        </Content>
      </FadeIn>
    </MainContainer>
  );
};

export default CasesPage;
