import React, { useEffect } from "react";

import { FadeIn } from "./HomePage.styles";

import HomePageSections from "./HomePageSections";
import MainContainer from "../../components/MainContainer/MainContainer";

const {
  HeroSection,
  WhatWeDoSection,
  KeyFeaturesSection,
  HowItWorksSection,
  MarketSectorsSection,
  SuccessStoriesSection,
  SeeMoreSection,
  RequestDemoSection,
  WhyChooseSection,
  GeneralQuestionsSection,
  BringTheCrowdSection,
  TransformTheWaySection,
  BringNewCostumersSection,
} = HomePageSections;

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <HeroSection />
        <WhatWeDoSection />
        <KeyFeaturesSection />
        <HowItWorksSection />
        <MarketSectorsSection />
        <SuccessStoriesSection />
        <SeeMoreSection />
        <RequestDemoSection />
        <WhyChooseSection />
        <GeneralQuestionsSection />
        <BringTheCrowdSection />
        <TransformTheWaySection />
        <BringNewCostumersSection />
      </FadeIn>
    </MainContainer>
  );
};

export default HomePage;
