import React, { useEffect } from "react";

import { FadeIn } from "./AboutPage.styles";

import AboutPageSections from "./AboutPageSections";
import MainContainer from "../../components/MainContainer/MainContainer";

const {
  HeroSection,
  OurJourneySection,
  MissionAndVisionSection,
  KeyFeaturesSection,
  GroundbreakingImpactSection,
  TheEyCrowdPromiseSection,
} = AboutPageSections;

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <HeroSection />
        <OurJourneySection />
        <MissionAndVisionSection />
        <KeyFeaturesSection />
        <GroundbreakingImpactSection />
        <TheEyCrowdPromiseSection />
      </FadeIn>
    </MainContainer>
  );
};

export default AboutPage;
