import { styled } from "styled-components";
import { ReactComponent as InstaSvg } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookSvg } from "../../assets/icons/facebook.svg";
import { ReactComponent as LinkedInSvg } from "../../assets/icons/linkedin.svg";
import { ReactComponent as TwitterSvg } from "../../assets/icons/twitter.svg";

import { ReactComponent as JoinTheWave } from "../../assets/icons/joinTheWave.svg";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  height: fit-content;
  width: 100%;

  background: #212121;
  gap: 4rem;
  padding: 4rem;
`;

export const Line = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const TextCopy = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.8rem;
  text-align: center;
`;

export const Text = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  line-height: 28px; /* 175% */
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10rem;
  width: 100%;
  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 5rem;
    justify-content: center;
  }
`;

export const FooterLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 32rem;
  @media (max-width: 1279px) {
    align-items: center;
    align-self: center;
  }
  @media (max-width: 350px) {
    width: 100%;
  }
`;

export const Logo = styled(JoinTheWave)`
  @media (max-width: 350px) {
    width: 100%;
  }
`;

export const SocialMediaRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const SocialMedia = styled.div`
  display: flex;
  width: 4.6rem;
  height: 4.6rem;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.06);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: transparent;
  }
`;

export const LinkedIn = styled(LinkedInSvg)`
  width: 1.4rem;
  height: 1.4rem;
`;
export const Instagram = styled(InstaSvg)`
  width: 1.4rem;
  height: 1.4rem;
`;
export const Facebook = styled(FacebookSvg)`
  width: 1.4rem;
  height: 1.4rem;
`;
export const X = styled(TwitterSvg)`
  width: 1.4rem;
  height: 1.4rem;
`;

export const FooterRightSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  max-width: 78rem;
  @media (max-width: 1279px) {
    flex-direction: column;
    max-width: unset;
    align-items: center;
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const MenuLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const MenuLinksRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.4rem;
  flex-wrap: wrap;
`;

export const FooterTitle = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-Bold";
  font-size: ${(props) => (props.$small ? "1.6rem" : "1.8rem")};
`;

export const FooterLinks = styled.span`
  color: #fff;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const FooterOffice = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  /* cursor: pointer; */
  line-height: 149.6%;
  transition: all 0.3s ease-in-out;

  &:hover {
    /* color: rgba(255, 255, 255, 1); */
  }
`;

export const SubscribeText = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
`;

export const OfficeLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  margin-top: 2rem;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
`;

export const Input = styled.input`
  border-radius: 10px;
  border: 1px solid rgba(147, 143, 153, 0.2);
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  width: 315px;
  height: 48px;
  padding: 0px 25px;
  outline: none;
  align-items: center;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
