import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FooterContainer,
  Logo,
  TextCopy,
  Line,
  FooterContent,
  FooterLeftSide,
  Text,
  SocialMediaRow,
  SocialMedia,
  LinkedIn,
  X,
  Facebook,
  Instagram,
  FooterRightSide,
  MenuLinksColumn,
  FooterTitle,
  MenuLinksRow,
  FooterLinks,
  OfficeLinks,
  FooterOffice,
  NewsletterContainer,
  SubscribeText,
  InputContainer,
  Input,
} from "./Footer.styles";
import Button from "../utils/Button/Button";

const Footer = () => {
  const navigate = useNavigate();

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const handleSearch = (searchText) => {
    const url = `https://www.google.com/search?q=${encodeURIComponent(
      searchText
    )}`;
    window.open(url, "_blank");
  };

  return (
    <FooterContainer>
      <FooterContent>
        <FooterLeftSide>
          <Logo />
          <Text>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Ultrices congue
            per arcu in ultricies quam.
          </Text>
          <SocialMediaRow>
            <SocialMedia
              onClick={() => {
                openLink("https://www.linkedin.com/company/eycrowd");
              }}
            >
              <LinkedIn />
            </SocialMedia>
            <SocialMedia
              onClick={() => {
                openLink("https://x.com/eycrowdinc/");
              }}
            >
              <X />
            </SocialMedia>
            <SocialMedia
              onClick={() => {
                openLink("https://www.facebook.com/eycrowd/");
              }}
            >
              <Facebook />
            </SocialMedia>
            <SocialMedia
              onClick={() => {
                openLink("https://www.instagram.com/eycrowd/");
              }}
            >
              <Instagram />
            </SocialMedia>
          </SocialMediaRow>
        </FooterLeftSide>

        <FooterRightSide>
          <MenuLinksColumn>
            <FooterTitle>Menu</FooterTitle>
            <MenuLinksRow>
              <MenuLinksColumn>
                <FooterLinks onClick={() => navigate("/")}>Home</FooterLinks>
                <FooterLinks>Create a Brand Experience</FooterLinks>
                <FooterLinks onClick={() => navigate("/business-faqs")}>
                  Business FAQ's
                </FooterLinks>

                <FooterLinks onClick={() => navigate("/supportrequest")}>
                  Support
                </FooterLinks>
              </MenuLinksColumn>
              <MenuLinksColumn>
                <FooterLinks onClick={() => navigate("/cookies-policy")}>
                  Cookie Policy
                </FooterLinks>
                <FooterLinks onClick={() => navigate("/terms-and-conditions")}>
                  Terms and Conditions
                </FooterLinks>
                <FooterLinks onClick={() => navigate("/eula")}>
                  End User License Agreement
                </FooterLinks>

                <FooterLinks onClick={() => navigate("/privacy")}>
                  Privacy Policy
                </FooterLinks>
              </MenuLinksColumn>
            </MenuLinksRow>
            {/* <NewsletterContainer>
              <FooterTitle>Newsletter</FooterTitle>
              <SubscribeText>
                Subscribe to our new channel to get latest updates
              </SubscribeText>
              <InputContainer>
                <Input placeholder="Your Email Address" />
                <Button title={"Send"} />
              </InputContainer>
            </NewsletterContainer> */}
          </MenuLinksColumn>

          <MenuLinksColumn>
            <FooterTitle>Offices</FooterTitle>
            <OfficeLinks>
              <FooterOffice>Levis PlazaSan Francisco, California</FooterOffice>
              <FooterOffice>
                Millennium Playa VistaLos Angeles, California
              </FooterOffice>
              <FooterOffice>Civica Cherry Greek Denver, Colorado</FooterOffice>
              <FooterOffice>
                Headquarters: Galleria Office Tower Houston, Texas
              </FooterOffice>
              <FooterTitle $small>Kingdom of Saudi Arabia</FooterTitle>
              <FooterOffice>Smart Tower Riyadh 12313</FooterOffice>
              <FooterTitle $small>Republic of Serbia</FooterTitle>
              <FooterOffice>Futoska Novi Sad 21000</FooterOffice>
            </OfficeLinks>
          </MenuLinksColumn>
        </FooterRightSide>
      </FooterContent>
      <Line />
      <TextCopy>Copyright © 2025 EyCrowd, Inc. - All Rights Reserved.</TextCopy>
    </FooterContainer>
  );
};

export default Footer;
