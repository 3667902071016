import React, { useEffect } from "react";

import { FadeIn } from "./CaseStudyPage.styles";

import CaseStudyPageSections from "./CaseStudyPageSections.js";
import MainContainer from "../../components/MainContainer/MainContainer";

const {
  HeroSection,
  ProblemSolutionSection,
  SmartSchedulingSection,
  HigherSalesSection,
  OurUserKindSection,
} = CaseStudyPageSections;

const CaseStudyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <HeroSection />
        <ProblemSolutionSection />
        <SmartSchedulingSection />
        <HigherSalesSection />
        <OurUserKindSection />
      </FadeIn>
    </MainContainer>
  );
};

export default CaseStudyPage;
