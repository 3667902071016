import React from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 1.4rem;
  background-color: #f4f6fd;
  border-radius: 4rem;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  width: ${(props) => props.value}%;
  border-radius: 4rem;
  height: 100%;
  transition: width 0.3s ease-in-out;
`;

const ProgressBar = ({ value, color }) => {
  return (
    <ProgressBarContainer>
      <ProgressFill value={value} style={{ backgroundColor: color }} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
