import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActiveDot,
  ActiveUser,
  ActiveUserCircle,
  ActiveUserPlus,
  ActiveUsers,
  ActiveUsersContainer,
  ActiveUserSmallText,
  ActiveUserText,
  ActiveUserTextContainer,
  ActiveUserTextRow,
  Answer,
  BasicRow,
  BringTheCrowdLeftSide,
  Buttons,
  CorrectIcon,
  CustomShape,
  Div,
  FeatureCard,
  FeatureIcon1,
  FeatureIcon2,
  FeatureIcon3,
  FeatureIcon4,
  FeatureIcon5,
  FeaturesContainer,
  FeatureText,
  FeatureTitle,
  GeneralQuestionLeftSide,
  GifImg8,
  GifRedContainer,
  GradientContainer,
  GradientName,
  GradientNameColumn,
  GradientNameContainer,
  GradientRole,
  GradientText,
  GradientUserProfilePhoto,
  HaveMoreQuestionContainer,
  HaveMoreText,
  HaveMoreTextContainer,
  HaveMoreTitle,
  HowItWorkContent,
  HowItWorkImg,
  HowItWorkLeftSide,
  HowItWorkRightSide,
  ImageGlassFrame,
  ImageGroup4,
  ImagesColumn,
  ImagesFirst,
  ImagesRow,
  ImagesSecond,
  ImagesThird,
  Img1,
  Img11,
  Img12,
  Img15,
  Img16,
  Img2,
  Img3,
  Img4,
  Img5,
  Label,
  Label2,
  LabelContainer,
  LeftPhotoAbsolute,
  LinearGradient,
  MarketSectorContainer,
  MarketSectors,
  MarketSectorText,
  MarketSectorTextContainer,
  OurPromiseContainer,
  OurPromiseText,
  PaginationDot,
  PaginationDots,
  Plus,
  PlusContainer,
  Question,
  QuestionColumn,
  QuestionRow,
  Questions,
  RedLine,
  Relative,
  RequestDemoImageBig,
  RequestDemoImageColumn,
  RequestDemoImagesContainer,
  RequestDemoImageSmall,
  RequestDemoLeftSide,
  RequestDemoRightSide,
  RequestDemoText,
  RequestDemoTextBox,
  RequestDemoTextInBox,
  RequestTextContainer,
  RightPhotoAbsolute,
  Section,
  SectionLeftSide,
  SectionMediumTitle,
  SectionRightSide,
  SectionSubtitle,
  SectionTitle,
  SectionTitleRow,
  SeeMoreButton,
  SeeMoreSection,
  SeeMoreSmallText,
  SeeMoreText,
  ShapeContainer,
  ShowSection,
  ShowSectionColumn,
  ShowSectionRow,
  StepDot,
  StepDotContainer,
  StepIcon1,
  StepIcon2,
  StepIcon3,
  StepIcon4,
  StepIcon5,
  StepIconContainer,
  StepInfo,
  StepsLine,
  StepsLineCustom,
  StepText,
  StepTextContainer,
  StoriesContainer,
  StoryContainer,
  StoryLeftSide,
  StoryPhoto,
  StoryPhotoExample,
  StoryRightSide,
  StoryText,
  StoryTextRow,
  StyledGif1,
  StyledGif2,
  StyledGif3,
  StyledGif4,
  StyledSectionMediumTitle,
  StyledSectionTitleRow,
  StyledSeeMoreSection,
  StyledSubtitle,
  VectorIcon,
  VectorIcon2,
  VectorIcon3,
  VectorIcon4,
  WhyChooseRedContainer,
  WhyChooseRightSideColumn,
  YellowCircle,
  YellowCircleLeftTop,
  YellowCircleRightTop,
} from "./HomePage.styles";

import Image1a from "../../assets/images/redesign/picture1a.jpg";
import Image1b from "../../assets/images/redesign/image1a.jpg";
import ActiveUser1 from "../../assets/images/redesign/activeuser1.jpeg";
import ActiveUser2 from "../../assets/images/redesign/activeuser2.jpeg";
import ActiveUser3 from "../../assets/images/redesign/activeuser3.jpeg";
import ActiveUser4 from "../../assets/images/redesign/activeuser4.jpeg";
import Group1 from "../../assets/images/redesign/redFrame.png";
import Image2 from "../../assets/images/redesign/image2.jpeg";
import Image3 from "../../assets/images/redesign/image3.jpeg";
import Image4 from "../../assets/images/redesign/image4.jpeg";
import Gif1 from "../../assets/images/redesign/gif1.gif";
import Gif2 from "../../assets/images/redesign/gif2.gif";

import Gif4 from "../../assets/images/redesign/gif4.gif";
import Gif6 from "../../assets/images/redesign/gif7.gif";
import Gif8 from "../../assets/images/redesign/gif8.gif";
import Group2 from "../../assets/images/redesign/group10.png";
import Group3 from "../../assets/images/redesign/group2.png";
import Image6 from "../../assets/images/redesign/image6.png";
import Image7 from "../../assets/images/redesign/image7.jpeg";
import Image8 from "../../assets/images/redesign/image8.jpg";
import Image9 from "../../assets/images/redesign/image9.jpg";
import Graph1 from "../../assets/images/redesign/graph1.png";
import Graph2 from "../../assets/images/redesign/graph2.png";
import Image10 from "../../assets/images/redesign/image13.jpeg";
import Gif5 from "../../assets/images/redesign/gif5.gif";
import Image12 from "../../assets/images/redesign/image25.jpeg";
import Image13 from "../../assets/images/redesign/image60.png";
import Group4 from "../../assets/images/redesign/group7.png";

import Image16 from "../../assets/images/redesign/image19.jpeg";
import Image17 from "../../assets/images/redesign/image18a.jpg";
import Imageee from "../../assets/images/redesign/imagee.jpeg";
import Imagee from "../../assets/images/redesign/image59.jpg";
import Image18 from "../../assets/images/redesign/image14a.jpg";
import Image53 from "../../assets/images/redesign/image53.jpg";
import Image55 from "../../assets/images/redesign/image55.png";
import Image56 from "../../assets/images/redesign/image56.jpg";
import Image57 from "../../assets/images/redesign/image57.jpg";

import Button from "../../components/utils/Button/Button";

import { marketSectors, successStories, faqData } from "../../utils/utils";
import CountUp from "react-countup";

// import ToggleSwitch from "../../components/utils/ToggleSwitch";

// Stilovi za modal
const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    width: "90%",
    maxWidth: "700px",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    border: "none",
    background: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
};

const HomePageSections = {
  HeroSection: () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    return (
      <>
        {/* <ToggleSwitch marginTop /> */}
        <Section $firstSection>
          <SectionLeftSide>
            <LabelContainer>
              <RedLine />
              <Label>Welcome to EyCrowd</Label>
            </LabelContainer>

            <SectionTitle $marginTop>
              Bring the
              <SectionTitle $red> Crowd</SectionTitle>
            </SectionTitle>
            <SectionTitle>
              Real people @
              <SectionTitle $red> EyCrowd</SectionTitle>
            </SectionTitle>

            <SectionSubtitle>
              We bring new customers to try your products on-demand, in-store
              AND online, complete with UGC of their experience. 100%
              done-for-you service. Pay-per-patron from $20. Results guaranteed.
            </SectionSubtitle>

            <Buttons>
              <Button
                title="Book a Quick Call"
                containerStyle={{ width: 243.4 }}
                onClick={() => setIsModalOpen(true)}
              />

              <Button
                title={"Subscribe to Our Magazine"}
                onClick={() => navigate("/e-magazine")}
              />

              {isModalOpen && (
                <div style={modalStyles.overlay}>
                  <div style={modalStyles.modal}>
                    <button
                      style={modalStyles.closeButton}
                      onClick={() => setIsModalOpen(false)}
                    >
                      ✖
                    </button>
                    <iframe
                      src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                      style={{ border: 0, width: "100%", height: "500px" }}
                      frameBorder="0"
                    ></iframe>
                  </div>
                </div>
              )}
            </Buttons>

            <ActiveUsersContainer>
              <ActiveUsers>
                <ActiveUser
                  $first
                  src={ActiveUser1}
                  alt="EyCrowd - Active user"
                />
                <ActiveUser src={ActiveUser2} alt="EyCrowd - Active user" />
                <ActiveUser src={ActiveUser3} alt="EyCrowd - Active user" />
                <ActiveUser src={ActiveUser4} alt="EyCrowd - Active user" />
                <ActiveUserCircle>
                  <ActiveUserPlus>+</ActiveUserPlus>
                </ActiveUserCircle>
              </ActiveUsers>
              <ActiveUserTextContainer>
                <ActiveUserText>
                  <CountUp start={10} end={20} delay={2} />
                  K+ & Growing Fast
                </ActiveUserText>
                <ActiveUserTextRow>
                  <ActiveDot />
                  <ActiveUserSmallText>
                    Active Users Worldwide
                  </ActiveUserSmallText>
                </ActiveUserTextRow>
              </ActiveUserTextContainer>
            </ActiveUsersContainer>
          </SectionLeftSide>

          <SectionRightSide>
            <Img1 src={Image1a} alt="EyCrowd - Photo" />
          </SectionRightSide>

          <YellowCircle />
        </Section>

        <Relative>
          <GifRedContainer src={Group1} />
          <ImagesFirst>
            <ImageGlassFrame>
              <Img2 src={Image2} alt="EyCrowd - Photo" />
            </ImageGlassFrame>
            <StyledGif1 src={Gif1} />
          </ImagesFirst>

          <ImagesSecond>
            <Img3 src={Image3} alt="EyCrowd - Photo" />
            <StyledGif2 src={Gif2} />
          </ImagesSecond>

          <ImageGlassFrame $marginHorizontal>
            <StyledGif3 src={Gif6} />
          </ImageGlassFrame>

          <ImagesThird>
            <StyledGif4 src={Gif4} />
            <ImageGlassFrame>
              <Img4 src={Image4} alt="EyCrowd - Photo" />
            </ImageGlassFrame>
          </ImagesThird>
        </Relative>
      </>
    );
  },
  WhatWeDoSection: () => (
    <Div>
      <SectionLeftSide>
        <Img5 src={Group2} alt="EyCrowd - Photo" />
        <GifImg8 src={Gif8} alt="EyCrowd - Gif" />
      </SectionLeftSide>

      <SectionRightSide>
        <LabelContainer>
          <RedLine />
          <Label>What We Do</Label>
        </LabelContainer>

        <SectionTitle>
          Remember how exciting it is when customers{" "}
          <SectionTitle $offCapitalize $red>
            discover your products?
          </SectionTitle>
        </SectionTitle>

        <StoryTextRow>
          <CorrectIcon />
          <StoryText>
            EyCrowd brings that feeling back by delivering real people to try
            your products exactly when and where you need them.
          </StoryText>
        </StoryTextRow>

        <StoryTextRow>
          <CorrectIcon />
          <StoryText>
            You decide when customers arrive. We handle everything else. From 50
            to 5,000 new customers weekly with just a few weeks' notice.
          </StoryText>
        </StoryTextRow>

        <StoryTextRow>
          <CorrectIcon />
          <StoryText>
            You only pay when customers actually show up. No visits, no charge.
          </StoryText>
        </StoryTextRow>
      </SectionRightSide>

      <YellowCircle $left />
    </Div>
  ),
  KeyFeaturesSection: () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <Section $column $alignItemsCenter>
        <SectionTitleRow>
          <SectionTitle>Key</SectionTitle>
          <SectionTitle $red>Features</SectionTitle>
        </SectionTitleRow>
        <FeaturesContainer>
          <FeatureCard>
            <FeatureIcon1 />
            <FeatureTitle>New Customers On-Demand</FeatureTitle>
            <FeatureText>
              Real people try your products when and where you need them.
              Pay-per-patron from $20. You only pay when they show up.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon2 />
            <FeatureTitle>Photos and Videos Included</FeatureTitle>
            <FeatureText>
              Every customer creates high-quality photos, videos, and reviews as
              they try your products. Use these in your marketing.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon3 />
            <FeatureTitle>You Do Nothing</FeatureTitle>
            <FeatureText>
              Unlike other programs that take hundreds of hours of work, EyCrowd
              takes just 10 minutes of your time. Everything is done for you.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon4 />
            <FeatureTitle>Works In-Store and Online</FeatureTitle>
            <FeatureText>
              Whether you have physical stores or sell online, we bring new
              customers to experience your products.
            </FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon5 />
            <FeatureTitle>Quality Guaranteed</FeatureTitle>
            <FeatureText>
              Our app makes sure every visit and photo meets high standards.
              This isn't just a promise—it's built into our technology.
            </FeatureText>
          </FeatureCard>
        </FeaturesContainer>
        <Buttons>
          <Button
            title="Book a Quick Call"
            containerStyle={{ width: 243.4 }}
            onClick={() => setIsModalOpen(true)}
          />

          <Button
            title={"Subscribe to Our Magazine"}
            onClick={() => navigate("/e-magazine")}
          />
        </Buttons>
        {isModalOpen && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <button
                style={modalStyles.closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                ✖
              </button>
              <iframe
                src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                style={{ border: 0, width: "100%", height: "500px" }}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        )}
      </Section>
    );
  },
  HowItWorksSection: () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <>
        <Section $column $alignItemsCenter $red $responsive>
          <LabelContainer>
            <RedLine />
            <Label2>For Brands</Label2>
            <RedLine />
          </LabelContainer>
          <SectionTitleRow>
            <SectionTitle>How It</SectionTitle>
            <SectionTitle $red>Works</SectionTitle>
          </SectionTitleRow>

          <HowItWorkContent>
            <HowItWorkLeftSide>
              <HowItWorkImg src={Image55} $left />
              <ShapeContainer>
                <StepIconContainer>
                  <StepIcon2 />
                </StepIconContainer>
                <CustomShape $left>
                  <StepText>2. We Bring The Customers</StepText>
                </CustomShape>
              </ShapeContainer>
              <StepTextContainer>
                <StepInfo>
                  Real people arrive on schedule to try your <br />
                  products—in-store or online.
                </StepInfo>
              </StepTextContainer>

              <HowItWorkImg src={Image7} $left />
              <ShapeContainer>
                <StepIconContainer>
                  <StepIcon4 />
                </StepIconContainer>
                <CustomShape $left>
                  <StepText>4. They Create Photos and Videos</StepText>
                </CustomShape>
              </ShapeContainer>
              <StepTextContainer>
                <StepInfo>
                  Our app guides them to take photos, videos, and share
                  thoughts.
                </StepInfo>
              </StepTextContainer>

              <HowItWorkImg src={Image9} $left />
            </HowItWorkLeftSide>

            <StepsLine src={Group3} />

            <HowItWorkRightSide>
              <ShapeContainer>
                <CustomShape>
                  <StepText>1. Tell Us What You Need</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon1 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer>
                <StepInfo>
                  How many new customers? Where? When? <br />
                  That's all we need to know.
                </StepInfo>
              </StepTextContainer>

              <HowItWorkImg src={Image6} />
              <ShapeContainer>
                <CustomShape>
                  <StepText>3. They Try Your Products</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon3 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer>
                <StepInfo>
                  Customers spend quality time with your products
                </StepInfo>
              </StepTextContainer>

              <HowItWorkImg src={Image8} />
              <ShapeContainer>
                <CustomShape>
                  <StepText>5. You Do Nothing</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon5 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer>
                <StepInfo>
                  No training, no setup, no management. Just watch the results.
                </StepInfo>
              </StepTextContainer>
            </HowItWorkRightSide>
          </HowItWorkContent>

          <OurPromiseContainer>
            <OurPromiseText $red>*</OurPromiseText>
            <OurPromiseText>
              <OurPromiseText $bold>Our Promise: </OurPromiseText>
              You only pay when customers actually show up and try your
              products. If they don't show up or don't meet our standards, you
              don't pay. This is built into our app.
            </OurPromiseText>
          </OurPromiseContainer>

          <Buttons>
            <Button
              title="Book a Quick Call"
              containerStyle={{ width: 243.4 }}
              onClick={() => setIsModalOpen(true)}
            />

            <Button
              title={"Subscribe to Our Magazine"}
              onClick={() => navigate("/e-magazine")}
            />
          </Buttons>
          {isModalOpen && (
            <div style={modalStyles.overlay}>
              <div style={modalStyles.modal}>
                <button
                  style={modalStyles.closeButton}
                  onClick={() => setIsModalOpen(false)}
                >
                  ✖
                </button>
                <iframe
                  src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                  style={{ border: 0, width: "100%", height: "500px" }}
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          )}
        </Section>

        <ShowSection>
          <LabelContainer>
            <RedLine />
            <Label2>For Brands</Label2>
            <RedLine />
          </LabelContainer>
          <SectionTitleRow $marginTopOff>
            <SectionTitle>How It</SectionTitle>
            <SectionTitle $red>Works</SectionTitle>
          </SectionTitleRow>
          <ShowSectionRow>
            <StepsLineCustom />
            <ShowSectionColumn>
              <ShapeContainer>
                <StepDotContainer>
                  <StepDot />
                </StepDotContainer>
                <CustomShape>
                  <StepText>1. Tell Us What You Need</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon1 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer style={{ margin: "1rem 0rem" }}>
                <StepInfo>
                  How many new customers? Where? When? That's all we need to
                  know.
                </StepInfo>
              </StepTextContainer>
              <HowItWorkImg src={Image55} style={{ margin: 0 }} />

              <ShapeContainer>
                <StepDotContainer>
                  <StepDot />
                </StepDotContainer>
                <CustomShape>
                  <StepText>2. We Bring The Customers</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon2 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer style={{ margin: "1rem 0rem" }}>
                <StepInfo>
                  Real people arrive on schedule to try your products—in-store
                  or online.
                </StepInfo>
              </StepTextContainer>
              <HowItWorkImg src={Image6} style={{ margin: 0 }} />

              <ShapeContainer>
                <StepDotContainer>
                  <StepDot />
                </StepDotContainer>
                <CustomShape>
                  <StepText>3. They Try Your Products</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon3 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer style={{ margin: "1rem 0rem" }}>
                <StepInfo>
                  Customers spend quality time with your products
                </StepInfo>
              </StepTextContainer>
              <HowItWorkImg src={Image7} style={{ margin: 0 }} />

              <ShapeContainer>
                <StepDotContainer>
                  <StepDot />
                </StepDotContainer>
                <CustomShape>
                  <StepText>4. They Create Photos and Videos</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon4 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer style={{ margin: "1rem 0rem" }}>
                <StepInfo>
                  Our app guides them to take photos, videos, and share
                  thoughts.
                </StepInfo>
              </StepTextContainer>

              <HowItWorkImg src={Image8} style={{ margin: 0 }} />

              <ShapeContainer>
                <StepDotContainer>
                  <StepDot />
                </StepDotContainer>
                <CustomShape>
                  <StepText>5. You Do Nothing</StepText>
                </CustomShape>
                <StepIconContainer>
                  <StepIcon5 />
                </StepIconContainer>
              </ShapeContainer>
              <StepTextContainer style={{ margin: "1rem 0rem" }}>
                <StepInfo>
                  No training, no setup, no management. Just watch the results.
                </StepInfo>
              </StepTextContainer>
              <HowItWorkImg src={Image9} style={{ margin: 0 }} />
            </ShowSectionColumn>
          </ShowSectionRow>
        </ShowSection>
      </>
    );
  },
  MarketSectorsSection: () => {
    const navigate = useNavigate();
    return (
      <Section $column $alignItemsCenter $hidden>
        <YellowCircleRightTop />
        <LabelContainer>
          <RedLine />
          <Label2 $light>Who We Serve</Label2>
          <RedLine />
        </LabelContainer>
        <SectionTitleRow>
          <SectionTitle>Market</SectionTitle>
          <SectionTitle $red>Sectors</SectionTitle>
        </SectionTitleRow>
        <SectionSubtitle $big>
          EyCrowd has helped thousands of businesses across every retail type.
          From bakeries to department stores, restaurants to specialty shops—we
          bring new customers on-demand. Whether you're a seasoned industry
          leader or an up-and-coming brand, our platform helps you bring new
          customers to experience your products with guaranteed results.
        </SectionSubtitle>

        <SectionSubtitle $big $bold $marginOff>
          EyCrowd works for virtually any retail business in sectors including:
        </SectionSubtitle>

        <MarketSectors>
          {marketSectors.map((marketSector) => (
            <MarketSectorContainer key={marketSector.id}>
              <MarketSectorTextContainer>
                <CorrectIcon width={20} height={20} />

                <MarketSectorText>
                  <MarketSectorText $bold>
                    {marketSector.boldText}{" "}
                  </MarketSectorText>
                  {marketSector.text}
                </MarketSectorText>
              </MarketSectorTextContainer>
            </MarketSectorContainer>
          ))}
          <LinearGradient />
          {/* <Button
            title={"See More Success Stories"}
            onClick={() => navigate("/cases")}
          /> */}
        </MarketSectors>

        <SectionSubtitle $big>
          Want to see how EyCrowd can help grow your business? Contact us for
          case studies tailored to your specific industry.
        </SectionSubtitle>
      </Section>
    );
  },
  SuccessStoriesSection: () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1200);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <Section $column $alignItemsCenter>
        <YellowCircleLeftTop />
        <LabelContainer>
          <RedLine />
          <Label2 $light>Success Stories</Label2>
          <RedLine />
        </LabelContainer>
        <SectionTitleRow>
          <SectionTitle>Hear From</SectionTitle>
          <SectionTitle $red>Businesses</SectionTitle>
        </SectionTitleRow>
        <SectionTitleRow $marginTopOff>
          <SectionTitle $red>Thriving</SectionTitle>
          <SectionTitle>With EyCrowd!</SectionTitle>
        </SectionTitleRow>

        <StoriesContainer>
          {isMobile ? (
            // Prikaz samo jednog storija ako je širina ekrana ≤ 1200px
            <StoryContainer
              key={successStories[currentIndex].id}
              $reverse={successStories[currentIndex].id === 2}
            >
              <StoryLeftSide>
                <StoryPhoto
                  src={successStories[currentIndex].storyPhoto}
                  alt="EyCrowd - Photo"
                />
                {/* <StoryPhotoExample
                  src={successStories[currentIndex].example}
                  alt="EyCrowd - Example"
                /> */}
              </StoryLeftSide>

              <StoryRightSide>
                <SectionTitleRow $marginTopOff>
                  <SectionTitle $small>
                    {successStories[currentIndex].title}
                  </SectionTitle>
                  <SectionTitle $small $red>
                    {successStories[currentIndex].titleRed}
                  </SectionTitle>
                </SectionTitleRow>
                <StoryText>
                  <StoryText $bold>The Problem: </StoryText>
                  {successStories[currentIndex].text1}
                </StoryText>
                <StoryText>
                  <StoryText $bold>The Solution: </StoryText>
                  {successStories[currentIndex].text2}
                </StoryText>
                <StoryText>
                  <StoryText $bold>The Results: </StoryText>
                  {successStories[currentIndex].text3}
                </StoryText>

                <GradientContainer>
                  <GradientText>
                    {successStories[currentIndex].gradientText}
                  </GradientText>

                  <GradientNameContainer>
                    <GradientUserProfilePhoto
                      src={successStories[currentIndex].profilePhoto}
                      alt="EyCrowd - Profile Photo"
                    />
                    <GradientNameColumn>
                      <GradientRole>
                        {successStories[currentIndex].role}
                      </GradientRole>
                      <GradientName>
                        {successStories[currentIndex].name}
                      </GradientName>
                    </GradientNameColumn>
                  </GradientNameContainer>

                  <VectorIcon />
                  <VectorIcon $right />
                </GradientContainer>
              </StoryRightSide>
            </StoryContainer>
          ) : (
            // Prikaz svih storija ako je širina ekrana > 1200px
            successStories.map((story) => (
              <StoryContainer key={story.id} $reverse={story.id === 2}>
                <StoryLeftSide>
                  <StoryPhoto src={story.storyPhoto} alt="EyCrowd - Photo" />
                  {/* <StoryPhotoExample
                    src={story.example}
                    alt="EyCrowd - Example"
                  /> */}
                </StoryLeftSide>

                <StoryRightSide>
                  <SectionTitleRow $marginTopOff>
                    <SectionTitle $small>{story.title}</SectionTitle>
                    <SectionTitle $small $red>
                      {story.titleRed}
                    </SectionTitle>
                  </SectionTitleRow>
                  <StoryText>
                    <StoryText $bold>The Problem: </StoryText>
                    {story.text1}
                  </StoryText>
                  <StoryText>
                    <StoryText $bold>The Solution: </StoryText>
                    {story.text2}
                  </StoryText>
                  <StoryText>
                    <StoryText $bold>The Results: </StoryText>
                    {story.text3}
                  </StoryText>

                  <GradientContainer>
                    <GradientText>{story.gradientText}</GradientText>

                    <GradientNameContainer>
                      <GradientUserProfilePhoto
                        src={story.profilePhoto}
                        alt="EyCrowd - Profile Photo"
                      />
                      <GradientNameColumn>
                        <GradientRole>{story.role}</GradientRole>
                        <GradientName>{story.name}</GradientName>
                      </GradientNameColumn>
                    </GradientNameContainer>

                    <VectorIcon />
                    <VectorIcon $right />
                  </GradientContainer>
                </StoryRightSide>
              </StoryContainer>
            ))
          )}
        </StoriesContainer>

        {/* Dugmad za navigaciju samo ako je ekran ≤ 1200px */}
        {isMobile && (
          <PaginationDots>
            {successStories.map((story, i) => (
              <PaginationDot
                key={i}
                onClick={() => setCurrentIndex(i)}
                $active={i === currentIndex}
              />
            ))}
          </PaginationDots>
        )}
      </Section>
    );
  },
  SeeMoreSection: () => {
    const navigate = useNavigate();
    return (
      <SeeMoreSection>
        <VectorIcon2 />
        <VectorIcon3 />
        <SeeMoreText>
          Enquire today to receive case studies relevant to your industry and
          discover how EyCrowd can elevate your brand!
        </SeeMoreText>
        <SeeMoreSmallText>
          Reach out now and take the first step toward creating a lasting
          impact.
        </SeeMoreSmallText>
        {/* <SeeMoreButton
          onClick={() => {
            navigate("/cases");
          }}
        >
          See More Success Stories
        </SeeMoreButton> */}
      </SeeMoreSection>
    );
  },
  RequestDemoSection: () => (
    <Section>
      <RequestDemoLeftSide>
        <SectionMediumTitle $red>Revolutionizing Marketing</SectionMediumTitle>
        <StyledSectionMediumTitle>
          with EyCrowd's Innovations
        </StyledSectionMediumTitle>
        <RequestTextContainer>
          <RequestDemoText>
            EyCrowd is transforming promotional marketing by combining
            authenticity and the human touch with the power of cutting-edge
            generative AI, innovative strategies, and novel techniques. Our
            platform delivers hyper-personalized, authentic brand experiences
            both online and in person, setting a new standard for audience
            engagement.
          </RequestDemoText>
          <RequestDemoText>
            At the heart of EyCrowd is a dynamic integration of advanced
            technologies, including use of multiple generative AI models,
            machine learning, gamification, reverse natural language processing,
            sentiment analysis, behavioral insights, and innovative applied
            mathematics. These technologies and processes work together to
            create brand experience campaigns that are not only sophisticated
            but also invigorating and are deeply aligned with your audience's
            interests and values.
          </RequestDemoText>
          <RequestDemoText>
            By prioritizing authenticity and values-driven experience
            optimization, EyCrowd crafts impactful campaigns that amplify the
            human experience. The result? A smart, unparalleled platform and app
            that deliver memorable brand experiences with lasting impact. With
            EyCrowd, your brand connects on a deeper level, leaving an
            impression your audience won't forget.
          </RequestDemoText>
        </RequestTextContainer>
        {/* <Button title={"Request Demo Today!"} /> */}
      </RequestDemoLeftSide>

      <RequestDemoRightSide>
        <RequestDemoImagesContainer>
          <RequestDemoImageColumn>
            <RequestDemoImageSmall src={Graph1} />
            <RequestDemoImageBig src={Gif5} />
          </RequestDemoImageColumn>
          <RequestDemoImageColumn>
            <RequestDemoImageBig src={Image10} />
            <RequestDemoImageSmall src={Graph2} />
          </RequestDemoImageColumn>
        </RequestDemoImagesContainer>
        <RequestDemoTextBox>
          <RequestDemoTextInBox>
            Ready to introduce your products and services to new customers with
            EyCrowd?
          </RequestDemoTextInBox>
        </RequestDemoTextBox>
      </RequestDemoRightSide>
    </Section>
  ),
  WhyChooseSection: () => {
    const containers = [
      {
        id: 1,
        component: (
          <WhyChooseRedContainer $minHeight>
            <Img11 src={Image18} alt="EyCrowd - Photo" />
            <WhyChooseRightSideColumn>
              <SectionTitleRow $marginTopOff $marginBottom>
                <SectionTitle $small>Benefits for</SectionTitle>
                <SectionTitle $small $red>
                  All Stakeholders
                </SectionTitle>
              </SectionTitleRow>
              <StoryTextRow>
                <CorrectIcon />

                <StoryText>
                  <StoryText $bold>On-Demand New Customers: </StoryText>
                  Schedule real people to try your products exactly when and
                  where you need them—in-store AND online. Watch as customers
                  discover your products and create those magical moments of
                  connection. No hoping for traffic—just guaranteed visits when
                  you want them.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Zero Wasted Spend: </StoryText>
                  You only pay when customers actually show up and try your
                  products. Our app verifies each visit meets our quality
                  standards or you don't pay a cent. Finally, marketing with
                  100% accountability and zero risk.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Zero Work After: </StoryText>
                  Unlike programs that drain hundreds of hours from your team,
                  EyCrowd takes just 10 minutes to set up. After that, our
                  technology handles everything. No training, no extra staff, no
                  implementation headaches—just results.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Authentic Content That Works: </StoryText>
                  Every customer creates high-quality photos, videos, and
                  testimonials as they try your products. This authentic content
                  typically outperforms professional shoots at 1/16 the cost.
                  Use it across all your marketing channels with no additional
                  fees.
                </StoryText>
              </StoryTextRow>
            </WhyChooseRightSideColumn>
          </WhyChooseRedContainer>
        ),
      },
      {
        id: 2,
        component: (
          <WhyChooseRedContainer $reverse $minHeight>
            <ImagesRow>
              <Img12 src={Image12} alt="EyCrowd - Photo" />
              <Img12 src={Image56} alt="EyCrowd - Photo" />
            </ImagesRow>

            <WhyChooseRightSideColumn>
              <SectionTitleRow $marginTopOff $marginBottom>
                <SectionTitle $small>For Marketing </SectionTitle>
                <SectionTitle $small $red>
                  Leaders
                </SectionTitle>
              </SectionTitleRow>
              <StoryTextRow>
                <CorrectIcon />

                <StoryText>
                  <StoryText $bold>Perfect Attribution: </StoryText>
                  Connect marketing spend directly to customer visits and sales.
                  See exactly who tried your products and what content they
                  created. Marketing that delivers people, not just metrics.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Campaign Amplification: </StoryText>
                  Blend EyCrowd seamlessly with your existing marketing
                  programs. Use the authentic content across all channels to
                  boost performance and create consistent customer journeys.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Content Creation Engine: </StoryText>
                  Generate thousands of authentic content pieces at $3 each (vs.
                  $50 with traditional methods). Access a constant stream of
                  fresh, genuine content that resonates because it's real.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Dashboard: </StoryText>Performance Track
                  every visit, content piece, and conversion in real-time.
                  Adjust priorities and locations instantly based on what's
                  working. Marketing that adapts as fast as your business needs.
                </StoryText>
              </StoryTextRow>
            </WhyChooseRightSideColumn>
          </WhyChooseRedContainer>
        ),
      },
      {
        id: 3,
        component: (
          <WhyChooseRedContainer $minHeight>
            <ImagesColumn>
              <ImageGroup4 src={Group4} alt="EyCrowd - Photo" />
              <ImagesRow>
                <Img12 $small src={Image53} alt="EyCrowd - Photo" />
                <Img12 $small src={Imageee} alt="EyCrowd - Photo" />
              </ImagesRow>
            </ImagesColumn>
            <WhyChooseRightSideColumn>
              <SectionTitleRow $marginTopOff $marginBottom>
                <SectionTitle $small $red>
                  For Merchandising
                </SectionTitle>
                <SectionTitle $small>Leaders</SectionTitle>
              </SectionTitleRow>
              <StoryTextRow>
                <CorrectIcon />

                <StoryText>
                  <StoryText $bold>Product Discovery Control: </StoryText>
                  Direct customer attention to specific products, collections,
                  or areas. Ensure new launches, seasonal items, or
                  underperforming categories get the attention they deserve.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Real-Time Feedback: </StoryText>
                  See how customers interact with your products and
                  merchandising. Gather authentic reactions and behavioral
                  insights that no focus group can match.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Higher Conversion Rates: </StoryText>
                  EyCrowd visitors convert better because they spend quality
                  time with your products. Transform browsers into buyers
                  through guided discovery.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Location-Specific Optimization: </StoryText>
                  Tailor experiences for each location based on layout,
                  inventory, and goals. Maintain consistent quality while
                  addressing the unique needs of different stores.
                </StoryText>
              </StoryTextRow>
            </WhyChooseRightSideColumn>
          </WhyChooseRedContainer>
        ),
      },
      {
        id: 4,
        component: (
          <WhyChooseRedContainer $reverse $minHeight>
            <ImagesRow>
              <Img12 src={Imagee} alt="EyCrowd - Photo" />
              <Img12 src={Image13} alt="EyCrowd - Photo" />
            </ImagesRow>

            <WhyChooseRightSideColumn>
              <SectionTitleRow $marginTopOff $marginBottom>
                <SectionTitle $small>For Operations </SectionTitle>
                <SectionTitle $small $red>
                  Leaders
                </SectionTitle>
              </SectionTitleRow>
              <StoryTextRow>
                <CorrectIcon />

                <StoryText>
                  <StoryText $bold>Zero Operational Burden: </StoryText>
                  No staff training. No extra work. No disruption. Your team
                  continues business as usual while new customers arrive and
                  engage with your products.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Traffic Pattern Control: </StoryText>
                  Schedule visits during slow periods or distribute throughout
                  the day. Prevent overwhelming your staff while maximizing
                  opportunity during ideal times.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Seamless Integration: </StoryText>
                  No technical setup, no system integration, no equipment
                  needed. Our app and platform work independently of your
                  existing operations.
                </StoryText>
              </StoryTextRow>

              <StoryTextRow>
                <CorrectIcon />
                <StoryText>
                  <StoryText $bold>Scalable Implementation: </StoryText>
                  Performance Track every Start with a single location and scale
                  to hundreds. Adjust volume, timing, and frequency with just 48
                  hours' notice. Flexibility that adapts to your operational
                  realities.
                </StoryText>
              </StoryTextRow>
            </WhyChooseRightSideColumn>
          </WhyChooseRedContainer>
        ),
      },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1200);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <Section $column $alignItemsCenter>
        <SectionTitle>
          Why Choose <SectionTitle $red>EyCrowd</SectionTitle>
        </SectionTitle>

        {isMobile ? (
          containers[currentIndex].component
        ) : (
          <>
            <WhyChooseRedContainer>
              <Img11 src={Image18} alt="EyCrowd - Photo" />
              <WhyChooseRightSideColumn>
                <SectionTitleRow $marginTopOff $marginBottom>
                  <SectionTitle $small>Benefits for</SectionTitle>
                  <SectionTitle $small $red>
                    All Stakeholders
                  </SectionTitle>
                </SectionTitleRow>
                <StoryTextRow>
                  <CorrectIcon />

                  <StoryText>
                    <StoryText $bold>On-Demand New Customers: </StoryText>
                    Schedule real people to try your products exactly when and
                    where you need them—in-store AND online. Watch as customers
                    discover your products and create those magical moments of
                    connection. No hoping for traffic—just guaranteed visits
                    when you want them.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Zero Wasted Spend: </StoryText>
                    You only pay when customers actually show up and try your
                    products. Our app verifies each visit meets our quality
                    standards or you don't pay a cent. Finally, marketing with
                    100% accountability and zero risk.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Zero Work After: </StoryText>
                    Unlike programs that drain hundreds of hours from your team,
                    EyCrowd takes just 10 minutes to set up. After that, our
                    technology handles everything. No training, no extra staff,
                    no implementation headaches—just results.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Authentic Content That Works: </StoryText>
                    Every customer creates high-quality photos, videos, and
                    testimonials as they try your products. This authentic
                    content typically outperforms professional shoots at 1/16
                    the cost. Use it across all your marketing channels with no
                    additional fees.
                  </StoryText>
                </StoryTextRow>
              </WhyChooseRightSideColumn>
            </WhyChooseRedContainer>
            <WhyChooseRedContainer $reverse>
              <ImagesRow>
                <Img12 src={Image12} alt="EyCrowd - Photo" />
                <Img12 src={Image56} alt="EyCrowd - Photo" />
              </ImagesRow>

              <WhyChooseRightSideColumn $minHeight>
                <SectionTitleRow $marginTopOff $marginBottom>
                  <SectionTitle $small>For Marketing </SectionTitle>
                  <SectionTitle $small $red>
                    Leaders
                  </SectionTitle>
                </SectionTitleRow>
                <StoryTextRow>
                  <CorrectIcon />

                  <StoryText>
                    <StoryText $bold>Perfect Attribution: </StoryText>
                    Connect marketing spend directly to customer visits and
                    sales. See exactly who tried your products and what content
                    they created. Marketing that delivers people, not just
                    metrics.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Campaign Amplification: </StoryText>
                    Blend EyCrowd seamlessly with your existing marketing
                    programs. Use the authentic content across all channels to
                    boost performance and create consistent customer journeys.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Content Creation Engine: </StoryText>
                    Generate thousands of authentic content pieces at $3 each
                    (vs. $50 with traditional methods). Access a constant stream
                    of fresh, genuine content that resonates because it's real.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Dashboard: </StoryText>Performance Track
                    every visit, content piece, and conversion in real-time.
                    Adjust priorities and locations instantly based on what's
                    working. Marketing that adapts as fast as your business
                    needs.
                  </StoryText>
                </StoryTextRow>
              </WhyChooseRightSideColumn>
            </WhyChooseRedContainer>
            <WhyChooseRedContainer>
              <ImagesColumn>
                <ImageGroup4 src={Group4} alt="EyCrowd - Photo" />
                <ImagesRow>
                  <Img12 $small src={Image53} alt="EyCrowd - Photo" />
                  <Img12 $small src={Imageee} alt="EyCrowd - Photo" />
                </ImagesRow>
              </ImagesColumn>
              <WhyChooseRightSideColumn>
                <SectionTitleRow $marginTopOff $marginBottom>
                  <SectionTitle $small $red>
                    For Merchandising
                  </SectionTitle>
                  <SectionTitle $small>Leaders</SectionTitle>
                </SectionTitleRow>
                <StoryTextRow>
                  <CorrectIcon />

                  <StoryText>
                    <StoryText $bold>Product Discovery Control: </StoryText>
                    Direct customer attention to specific products, collections,
                    or areas. Ensure new launches, seasonal items, or
                    underperforming categories get the attention they deserve.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Real-Time Feedback: </StoryText>
                    See how customers interact with your products and
                    merchandising. Gather authentic reactions and behavioral
                    insights that no focus group can match.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Higher Conversion Rates: </StoryText>
                    EyCrowd visitors convert better because they spend quality
                    time with your products. Transform browsers into buyers
                    through guided discovery.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>
                      Location-Specific Optimization:{" "}
                    </StoryText>
                    Tailor experiences for each location based on layout,
                    inventory, and goals. Maintain consistent quality while
                    addressing the unique needs of different stores.
                  </StoryText>
                </StoryTextRow>
              </WhyChooseRightSideColumn>
            </WhyChooseRedContainer>
            <WhyChooseRedContainer $reverse>
              <ImagesRow>
                <Img12 src={Imagee} alt="EyCrowd - Photo" />
                <Img12 src={Image13} alt="EyCrowd - Photo" />
              </ImagesRow>

              <WhyChooseRightSideColumn>
                <SectionTitleRow $marginTopOff $marginBottom>
                  <SectionTitle $small>For Operations </SectionTitle>
                  <SectionTitle $small $red>
                    Leaders
                  </SectionTitle>
                </SectionTitleRow>
                <StoryTextRow>
                  <CorrectIcon />

                  <StoryText>
                    <StoryText $bold>Zero Operational Burden: </StoryText>
                    No staff training. No extra work. No disruption. Your team
                    continues business as usual while new customers arrive and
                    engage with your products.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Traffic Pattern Control: </StoryText>
                    Schedule visits during slow periods or distribute throughout
                    the day. Prevent overwhelming your staff while maximizing
                    opportunity during ideal times.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Seamless Integration: </StoryText>
                    No technical setup, no system integration, no equipment
                    needed. Our app and platform work independently of your
                    existing operations.
                  </StoryText>
                </StoryTextRow>

                <StoryTextRow>
                  <CorrectIcon />
                  <StoryText>
                    <StoryText $bold>Scalable Implementation: </StoryText>
                    Performance Track every Start with a single location and
                    scale to hundreds. Adjust volume, timing, and frequency with
                    just 48 hours' notice. Flexibility that adapts to your
                    operational realities.
                  </StoryText>
                </StoryTextRow>
              </WhyChooseRightSideColumn>
            </WhyChooseRedContainer>
          </>
        )}

        {/* Dugmad za navigaciju samo ako je ekran ≤ 1200px */}
        {isMobile && (
          <PaginationDots style={{ marginTop: 40 }}>
            {containers.map((story, i) => (
              <PaginationDot
                key={i}
                onClick={() => setCurrentIndex(i)}
                $active={i === currentIndex}
              />
            ))}
          </PaginationDots>
        )}

        <WhyChooseRedContainer $marginBottom>
          <WhyChooseRightSideColumn $offMaxWidth>
            <BasicRow>
              <OurPromiseText $red>*</OurPromiseText>
              <OurPromiseText>
                <OurPromiseText $bold>Zero Effort: </OurPromiseText>
                Our app attracts new customers to your store and captures
                authentic content and testimonials of them using your
                products—effortlessly, with no extra staff or setup needed.
              </OurPromiseText>
            </BasicRow>
            <BasicRow>
              <OurPromiseText $red>*</OurPromiseText>
              <OurPromiseText>
                <OurPromiseText $bold>Pay Only for Results: </OurPromiseText>
                Only pay for real results—new customers visiting you in-store or
                online, trying your products. The platform ensures a hassle-free
                experience by preventing fees for no-shows or subpar content,
                giving you peace of mind. It's built in!
              </OurPromiseText>
            </BasicRow>
          </WhyChooseRightSideColumn>
        </WhyChooseRedContainer>

        <Buttons>
          <Button
            title="Book a Quick Call"
            containerStyle={{ width: 243.4 }}
            onClick={() => setIsModalOpen(true)}
          />

          <Button
            title={"Subscribe to Our Magazine"}
            onClick={() => navigate("/e-magazine")}
          />
        </Buttons>
        {isModalOpen && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <button
                style={modalStyles.closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                ✖
              </button>
              <iframe
                src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                style={{ border: 0, width: "100%", height: "500px" }}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        )}
      </Section>
    );
  },
  GeneralQuestionsSection: () => {
    const [openIndex, setOpenIndex] = useState(0);

    const toggleAnswer = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };

    return (
      <Section $alignItemsCenter $column>
        <GeneralQuestionLeftSide>
          <LabelContainer>
            <RedLine />
            <Label2 $light>Frequently Asked Questions</Label2>
            <RedLine />
          </LabelContainer>
          <SectionTitleRow>
            <SectionTitle>General</SectionTitle>
            <SectionTitle $red>Questions</SectionTitle>
          </SectionTitleRow>

          <Questions>
            {faqData.map((item, index) => (
              <QuestionColumn key={index} $border $isOpen={openIndex === index}>
                <QuestionRow onClick={() => toggleAnswer(index)}>
                  <Question>{item.question}</Question>
                  <PlusContainer>
                    {openIndex !== index ? (
                      <Plus $active={openIndex !== index}>+</Plus>
                    ) : (
                      <Plus $active={openIndex === index}>-</Plus>
                    )}
                  </PlusContainer>
                </QuestionRow>
                <Answer $active={openIndex === index}>{item.answer}</Answer>
              </QuestionColumn>
            ))}

            <HaveMoreQuestionContainer>
              <HaveMoreTextContainer>
                <HaveMoreTitle>Have more questions?</HaveMoreTitle>
                <HaveMoreText>
                  We've got you covered. Explore our FAQs.
                </HaveMoreText>
              </HaveMoreTextContainer>
              <VectorIcon4 />
            </HaveMoreQuestionContainer>
            {/* <Button title={"Get Your Questions Answered"} /> */}
          </Questions>
        </GeneralQuestionLeftSide>
      </Section>
    );
  },
  BringTheCrowdSection: () => {
    return (
      <Section $red $alignItemsCenter style={{ minHeight: "100%", gap: 20 }}>
        <BringTheCrowdLeftSide>
          <StyledSectionTitleRow $marginTopOff>
            <SectionMediumTitle $red>Bring the Crowd</SectionMediumTitle>
            <StyledSectionMediumTitle>with EyCrowd</StyledSectionMediumTitle>
          </StyledSectionTitleRow>
          <StyledSubtitle $marginTop $center>
            Effortlessly bring new customers to experience your products through
            EyCrowd's groundbreaking platform delivering on-demand visits and
            trials, in-store or online. Watch your business grow as new
            customers discover your products wherever they are sold, all while
            we capture genuine photo, video, and testimonial content of their
            experience. With guaranteed results, you only pay for the new
            customers who arrive and try your offerings.
          </StyledSubtitle>
          <StyledSubtitle $marginTop $center>
            On-demand in-store and online visits and trials, complete with UGC.
            Pay-per-patron pricing from $20. Results guaranteed. 100%
            done-for-you service.
          </StyledSubtitle>
        </BringTheCrowdLeftSide>
        <Img15 src={Image57} alt="EyCrowd - Photo" />
      </Section>
    );
  },
  TransformTheWaySection: () => {
    return (
      <Section $smallHeight $alignItemsCenter>
        <Img16 src={Image17} alt="EyCrowd - Photo" />

        <BringTheCrowdLeftSide>
          <SectionMediumTitle>Transform the Way Customers</SectionMediumTitle>
          <SectionMediumTitle $red>See Your Brand</SectionMediumTitle>

          <StyledSubtitle $marginTop>
            With EyCrowd, you're not just increasing in-store and online traffic
            -- you're crafting lasting, memorable experiences that connect with
            your brand. It's time to elevate your marketing strategy with
            authenticity, reduce wasted spending, and achieve impactful results
            with our 100% done-for-you service. Guaranteed results.
          </StyledSubtitle>
          <StyledSubtitle $marginTop>
            Ready to take the next step? We're here to support you every step of
            the way.
          </StyledSubtitle>
        </BringTheCrowdLeftSide>
      </Section>
    );
  },
  BringNewCostumersSection: () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    return (
      <StyledSeeMoreSection>
        <SeeMoreText>
          Pay only when new customers try your products or services, in-store or
          online.
        </SeeMoreText>

        <Buttons>
          <SeeMoreButton onClick={() => setIsModalOpen(true)}>
            Book a Quick Call
          </SeeMoreButton>

          <SeeMoreButton onClick={() => navigate("/e-magazine")}>
            Subscribe to Our Magazine
          </SeeMoreButton>

          {isModalOpen && (
            <div style={modalStyles.overlay}>
              <div style={modalStyles.modal}>
                <button
                  style={modalStyles.closeButton}
                  onClick={() => setIsModalOpen(false)}
                >
                  ✖
                </button>
                <iframe
                  src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0MMArgPS_Jvn3uW11qqcHHsrGdoO-r7X0h10Eenz_ipbkpdnBaJ1gLaIjHgeEDORCmoAU6B78N?gv=true"
                  style={{ border: 0, width: "100%", height: "500px" }}
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          )}
        </Buttons>

        <LeftPhotoAbsolute src={Image1b} />
        <RightPhotoAbsolute src={Image16} />
      </StyledSeeMoreSection>
    );
  },
};

export default HomePageSections;
