import React, { useEffect } from "react";

import { FadeIn } from "./EmagazinePage.styles";

import EmagazinePageSections from "./EmagazinePageSections";
import MainContainer from "../../components/MainContainer/MainContainer";

const { HeroSection } = EmagazinePageSections;

const EmagazinePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <HeroSection />
      </FadeIn>
    </MainContainer>
  );
};

export default EmagazinePage;
