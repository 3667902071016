import React, { useState } from "react";
import {
  Section,
  SectionTitle,
  SectionTitleRow,
  SectionSubtitle,
  BgRed,
  LightBlueContainerLeft,
  IconContainer,
  SectionRow,
  ProfilePhoto,
  LightBlueContainerRight,
  Logo,
  RedLine,
  LabelContainer,
  Label2,
  InfoContainer,
  InfoContainerRow,
  LabelInfo,
  LabelInfoText,
  PersonalInformationContainer,
  InformationText,
  PersonalInformationContainerRow,
  SmallTitle,
  SmallLabel,
  SmallLabelContainer,
  PersonalInformationContainerColumn,
  TextDotRow,
  TextDot,
  TeamContainer,
  ProfileContainer,
  ProfileImage,
  NameContainer,
  Name,
  Role,
  YellowCircleBottom,
  SectionBlue,
  Input,
  InputLabel,
  InputsContainer,
  InputContainerColum,
  InputContainerRow,
  Textarea,
  PhotoRow,
  PhotoColumn,
  PhotoLong,
  PhotoShort,
  SectionBlueColumn,
  Instagram,
  X,
  LinkedIn,
  BgRed2,
  StyledProfilePhoto,
} from "./OurTeamPage.styles";

import ProfilePhotoImg from "../../assets/images/redesign/profilePhoto.jpeg";
import Profile1 from "../../assets/images/redesign/profile1.jpeg";
import Profile2 from "../../assets/images/redesign/profile2.png";
import Profile3 from "../../assets/images/redesign/profile3.png";
import Profile4 from "../../assets/images/redesign/profile4.png";
import Profile5 from "../../assets/images/redesign/profile5.png";
import Profile6 from "../../assets/images/redesign/profile6.png";
import Profile7 from "../../assets/images/redesign/profile7.png";
import Profile8 from "../../assets/images/redesign/profile8.png";
import Img1 from "../../assets/images/redesign/image4.jpeg";
import Img4 from "../../assets/images/redesign/image22.jpeg";
import Img2 from "../../assets/images/redesign/image40.jpeg";
import Img3 from "../../assets/images/redesign/image41.jpeg";

import { ReactComponent as RedBar } from "../../assets/icons/redBar.svg";
import { ReactComponent as YellowBar } from "../../assets/icons/yellowBar.svg";
import { ReactComponent as BlueBar } from "../../assets/icons/blueBar.svg";

import Button from "../../components/utils/Button/Button";
import ToggleSwitch from "../../components/utils/ToggleSwitch";
import ProgressBar from "../../components/utils/ProgressBar";

const PricingPageSections = {
  HeroSection: () => (
    <Section $alignItemsCenter $column $firstSection>
      <BgRed />
      <BgRed2 />
      <ToggleSwitch />
      <SectionTitleRow>
        <SectionTitle>Our</SectionTitle>
        <SectionTitle $red>Team</SectionTitle>
      </SectionTitleRow>
      <SectionSubtitle $big style={{ maxWidth: 880 }}>
        Our team is a blend of industry professionals dedicated to delivering
        top-tier solutions for businesses and consumers. With years of
        experience, we drive innovation, efficiency, and success for our
        partners.
      </SectionSubtitle>

      <SectionRow>
        <LightBlueContainerLeft>
          <IconContainer>
            <Instagram />
          </IconContainer>
          <IconContainer>
            <X />
          </IconContainer>

          <IconContainer>
            <LinkedIn />
          </IconContainer>
        </LightBlueContainerLeft>
        <ProfilePhoto src={ProfilePhotoImg} alt="Ey Crowd" />
        <LightBlueContainerRight>
          <StyledProfilePhoto src={ProfilePhotoImg} alt="Ey Crowd" />
          <Logo />
          <LabelContainer>
            <RedLine />
            <Label2 $offBorder>Business Advisor</Label2>
          </LabelContainer>
          <SectionTitle style={{ fontSize: 41, marginTop: -15 }} $red>
            John Doe
          </SectionTitle>
          <InfoContainer>
            <InfoContainerRow>
              <LabelInfo>Speciality:</LabelInfo>
              <LabelInfoText>General Manager</LabelInfoText>
            </InfoContainerRow>
            <InfoContainerRow>
              <LabelInfo>Experience:</LabelInfo>
              <LabelInfoText>10 years</LabelInfoText>
            </InfoContainerRow>
            <InfoContainerRow>
              <LabelInfo>Email:</LabelInfo>
              <LabelInfoText>example@john.com</LabelInfoText>
            </InfoContainerRow>
            <InfoContainerRow>
              <LabelInfo>Phone:</LabelInfo>
              <LabelInfoText>+8 (123) 456 780</LabelInfoText>
            </InfoContainerRow>
            <InfoContainerRow>
              <LabelInfo>Fax:</LabelInfo>
              <LabelInfoText>89 (103) 456 689</LabelInfoText>
            </InfoContainerRow>
          </InfoContainer>
        </LightBlueContainerRight>
      </SectionRow>

      <PersonalInformationContainer>
        <SectionTitleRow>
          <SectionTitle>Personal</SectionTitle>
          <SectionTitle $red>Information</SectionTitle>
        </SectionTitleRow>
        <InformationText>
          Lorem ipsum dolor sit amet consectetur. Varius amet elit pellentesque
          nisl vel at porttitor quam mi. In aliquam nam neque lorem mollis
          justo. Risus dignissim rutrum cras rutrum nulla convallis. At cursus
          vestibulum mi vitae non nec amet ac cras. Lorem ipsum dolor sit amet
          consectetur. Lorem ipsum dolor sit amet consectetur. Varius amet elit
          pellentesque nisl vel at porttitor quam mi. In aliquam nam neque lorem
          mollis justo. Risus dignissim rutrum cras rutrum nulla convallis. At
          cursus vestibulum mi vitae non nec amet ac cras. Lorem ipsum dolor sit
          amet consectetur. <br />
          Lorem ipsum dolor sit amet consectetur. Varius amet elit pellentesque
          nisl vel at porttitor quam mi. In aliquam nam neque lorem mollis
          justo. Risus dignissim rutrum cras rutrum nulla convallis. At cursus
          vestibulum mi vitae non nec amet ac cras.
        </InformationText>
        <PersonalInformationContainerRow>
          <PersonalInformationContainerColumn>
            <SmallTitle>Skills</SmallTitle>
            <SmallLabelContainer>
              <SmallLabel>Seo</SmallLabel>
              <SmallLabel>43%</SmallLabel>
            </SmallLabelContainer>
            <ProgressBar value={43} color={"#FE5F55"} />

            <SmallLabelContainer>
              <SmallLabel>Smm</SmallLabel>
              <SmallLabel>63%</SmallLabel>
            </SmallLabelContainer>

            <ProgressBar value={63} color={"#FFD700"} />

            <SmallLabelContainer>
              <SmallLabel>Ppc</SmallLabel>
              <SmallLabel>93%</SmallLabel>
            </SmallLabelContainer>

            <ProgressBar value={93} color={"#2E3190"} />
          </PersonalInformationContainerColumn>
          <PersonalInformationContainerColumn>
            <SmallTitle>Experience</SmallTitle>
            <TextDotRow>
              <TextDot />
              <InformationText>
                Lorem ipsum dolor sit amet consectetur. Varius amet elit
                pellentesque nisl vel at porttitor quam mi.
              </InformationText>
            </TextDotRow>
            <TextDotRow>
              <TextDot />
              <InformationText>
                Lorem ipsum dolor sit amet consectetur. Varius amet elit
                pellentesque nisl vel at porttitor quam mi.
              </InformationText>
            </TextDotRow>
            <TextDotRow>
              <TextDot />
              <InformationText>
                Lorem ipsum dolor sit amet consectetur. Varius amet elit
                pellentesque nisl vel at porttitor quam mi.
              </InformationText>
            </TextDotRow>
          </PersonalInformationContainerColumn>
        </PersonalInformationContainerRow>
      </PersonalInformationContainer>
    </Section>
  ),

  ExecutiveTeam: () => (
    <Section $alignItemsCenter $column>
      <SectionTitleRow style={{ marginTop: 100 }}>
        <SectionTitle>Our Executive</SectionTitle>
        <SectionTitle $red>Team</SectionTitle>
      </SectionTitleRow>
      <SectionSubtitle $big style={{ maxWidth: 880 }}>
        Lorem ipsum dolor sit amet consectetur. Varius amet elit pellentesque
        nisl vel at porttitor quam mi. In aliquam nam neque lorem mollis justo.
        Risus dignissim rutrum cras rutrum nulla convallis..
      </SectionSubtitle>

      <TeamContainer>
        <ProfileContainer>
          <ProfileImage src={Profile1} />
          <NameContainer>
            <Name>Natalya Brown</Name>
            <Role>Business Woman</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile2} />
          <NameContainer>
            <Name>Charles Tim</Name>
            <Role>Business Man</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile3} />
          <NameContainer>
            <Name>Christina Taylor</Name>
            <Role>Business Woman</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile4} />
          <NameContainer>
            <Name>Charles Tim</Name>
            <Role>Business Man</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile5} />
          <NameContainer>
            <Name>Jack Smith</Name>
            <Role>Business Man</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile6} />
          <NameContainer>
            <Name>Charlotte Smith</Name>
            <Role>Business Woman</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile7} />
          <NameContainer>
            <Name>Henry Evans</Name>
            <Role>Business Man</Role>
          </NameContainer>
        </ProfileContainer>
        <ProfileContainer>
          <ProfileImage src={Profile8} />
          <NameContainer>
            <Name>Isabella Jones</Name>
            <Role>Business Woman</Role>
          </NameContainer>
        </ProfileContainer>
        <YellowCircleBottom />
      </TeamContainer>
    </Section>
  ),

  ContactUs: () => (
    <SectionBlue $alignItemsCenter>
      <SectionBlueColumn>
        <SectionTitleRow>
          <SectionTitle $red>Contact</SectionTitle>
          <SectionTitle>Us</SectionTitle>
        </SectionTitleRow>
        <SectionSubtitle>
          Have questions? We're here to help—get in touch today!
        </SectionSubtitle>

        <InputsContainer>
          <InputContainerRow>
            <InputContainerColum>
              <InputLabel>
                First Name <InputLabel $red>*</InputLabel>
              </InputLabel>
              <Input />
            </InputContainerColum>
            <InputContainerColum>
              <InputLabel>
                Surname <InputLabel $red>*</InputLabel>
              </InputLabel>
              <Input />
            </InputContainerColum>
          </InputContainerRow>
          <InputContainerRow>
            <InputContainerColum>
              <InputLabel>
                Email address <InputLabel $red>*</InputLabel>
              </InputLabel>
              <Input />
            </InputContainerColum>
            <InputContainerColum>
              <InputLabel>
                Phone Number <InputLabel $red>*</InputLabel>
              </InputLabel>
              <Input />
            </InputContainerColum>
          </InputContainerRow>
          <InputContainerRow>
            <InputContainerColum>
              <InputLabel>
                Select Country <InputLabel $red>*</InputLabel>
              </InputLabel>
              <Input />
            </InputContainerColum>
            <InputContainerColum>
              <InputLabel>
                Select State <InputLabel $red>*</InputLabel>
              </InputLabel>
              <Input />
            </InputContainerColum>
          </InputContainerRow>
          <InputLabel>Write your message here</InputLabel>
          <Textarea />
          <Button title={"Submit"} containerStyle={{ width: "100%" }} />
        </InputsContainer>
      </SectionBlueColumn>
      <PhotoRow>
        <PhotoColumn>
          <PhotoLong src={Img1} />
          <PhotoShort src={Img2} />
        </PhotoColumn>
        <PhotoColumn>
          <PhotoLong src={Img3} />
          <PhotoShort src={Img4} />
        </PhotoColumn>
      </PhotoRow>
    </SectionBlue>
  ),
};

export default PricingPageSections;
