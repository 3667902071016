import React, { useEffect } from "react";

import { BgRed, Content, FadeIn, StyledIframe } from "./EmbedPage.styles";

import MainContainer from "../../components/MainContainer/MainContainer";

import { waveform } from "ldrs";
waveform.register();

const EmbedPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <FadeIn>
        <Content>

          <StyledIframe
            style={{ zIndex: 2 }}
            src="https://docs.google.com/forms/d/e/1FAIpQLSe_p0nXHrj7hXb3Nu-1Cv-o3Vindt2G6RLMcHpjpTA5lxtBBg/viewform?embedded=true"
            width="640"
            height="3200"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          />
          <BgRed style={{ zIndex: 1 }} />
        </Content>
      </FadeIn>
    </MainContainer>
  );
};

export default EmbedPage;
