import ProfilePhoto1 from "../assets/images/redesign/image10.jpeg";
import ProfilePhoto2 from "../assets/images/redesign/image12.jpeg";
import ProfilePhoto3 from "../assets/images/redesign/image11.jpeg";
import Group4 from "../assets/images/redesign/cake.jpeg";
import Group5 from "../assets/images/redesign/women.jpeg";
import Group6 from "../assets/images/redesign/snack.png";
import Example1 from "../assets/images/redesign/Example2.png";
import Example2 from "../assets/images/redesign/Example3.png";
import Example3 from "../assets/images/redesign/Example4.png";

export const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

// export const isValidPhone = (phone) => {
//   return /^[0-9]{8,15}$/.test(phone); // Proverava da li sadrži 8-15 cifara
// };

export const formatDate = (isoDate) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(isoDate);
  return date.toLocaleDateString("en-US", options);
};

export const faqData = [
  {
    question: "What is EyCrowd?",
    answer:
      "EyCrowd is the world's first brand experience app and smart platform. EyCrowd fosters authentic engagement between brands and their devoted customers, engaging in online and in-person brand experiences that entertain and reward, deepening loyalty among your top consumers and fans. Real people. Real connection. Brand safe. Welcome to the future of marketing.",
  },
  {
    question: "What is EyCrowd UGC, and why does it matter?",
    answer:
      "UGC (User-Generated Content) is content created by real people experiencing your brand. It builds trust, drives engagement, and feels way more authentic than traditional ads.",
  },
  {
    question: "What sets EyCrowd apart?",
    answer:
      "EyCrowd turns everyday people into brand EyVocates, creating real, authentic buzz around your business. Instead of traditional ads, we spark genuine interactions that drive awareness and trust.",
  },
  {
    question: "How is this different from regular advertising?",
    answer:
      "Instead of just talking to customers, EyCrowd gets them involved. People experience your brand firsthand and share their excitement, making your marketing feel organic and personal.",
  },
  {
    question: "This sounds too good to be true. What's the catch?",
    answer:
      "No catch—just real people sharing real experiences. Brands get authentic engagement, and our EyVocates get rewarded for participating. It’s a win-win for everyone!",
  },
  {
    question: "How soon can we start?",
    answer:
      "Right away! Sign up, create your first campaign, and start seeing results in no time. It’s that simple.",
  },
  {
    question: "What types of businesses does this work for?",
    answer:
      "EyCrowd works for any business looking to increase awareness and engagement. Whether you’re in retail, food & beverage, or entertainment, we bring your brand to life through real customer interactions.",
  },
  {
    question: "What does 'on-demand' mean?",
    answer:
      "On-demand means you can launch campaigns whenever you want—no waiting, no long planning cycles. Need engagement today? We’ve got you covered!",
  },
  {
    question: "How much work will my team need to do?",
    answer:
      "Not much! Our platform makes it easy to create and launch campaigns in minutes, and we handle the rest. Just set your goals, and we’ll take care of the engagement.",
  },
];

export const successStories = [
  {
    id: 1,
    title: "Success",
    storyPhoto: Group4,
    example: Example1,
    titleRed: "Bakery",
    text1: "Slow weekday mornings despite great products.",
    text2: "50 new customers weekly during slow periods.",
    text3: "265% sales growth, $24 average ticket value, 81% purchase rate.",
    gradientText: `EyCrowd brings us 50 new customers every week, like clockwork. It's been a total game-changer for boosting our sales right when we need it.`,
    role: "-Business Owner",
    name: "Regional Retail Bakery",
    profilePhoto: ProfilePhoto1,
  },
  {
    id: 2,
    title: "Success",
    storyPhoto: Group5,
    example: Example2,
    titleRed: "Retail Shop",
    text1: "Boost new customer traffic.",
    text2: "100 new weekly visitors scheduled during quieter times.",
    text3: "$31 average transaction, 300% sales spike, 5x ROI.",
    gradientText: `We're getting new customers every week, and many of them spend more than our regulars. I'm happy. EyCrowd works.`,
    role: "-General Manager",
    name: "Gourmet Food",
    profilePhoto: ProfilePhoto2,
  },
  {
    id: 3,
    title: "Retailer",
    storyPhoto: Group6,
    example: Example3,
    titleRed: "Big Box",
    text1: "Need for guaranteed product trials.",
    text2: "Managed 500+ weekly store visits across multiple locations.",
    text3: "Boosted sales by 310%, 72% trial-to-purchase conversion rate.",
    gradientText: `EyCrowd brings us 50 new customers every week, like clockwork. It's been a total game-changer for boosting our sales right when we need it.`,
    role: "-National Sales Director",
    name: "CPG Brand",
    profilePhoto: ProfilePhoto3,
  },
];

export const marketSectors = [
  {
    id: 1,
    boldText: "Apparel and Footwear:",
    text: "We bring new customers to try your casual wear, high-end fashion, or athletic gear, ensuring your products get the attention they deserve.",
  },
  {
    id: 2,
    boldText: "Baby Care:",
    text: "We ensure new parents discover and try your safe, reliable, and innovative products for infants and young children.",
  },
  {
    id: 3,
    boldText: "Bakeries and Confectioneries:",
    text: "From artisanal bread to sweet treats, we connect you with new customers eager to try your baked goods.",
  },
  {
    id: 4,
    boldText: "Books and Media:",
    text: "We connect curious minds with your captivating stories and inspiring reads, bringing new customers to explore your media offerings.",
  },
  {
    id: 5,
    boldText: "Catering Services:",
    text: "We help you attract new clients looking for exceptional catering experiences, making their events unforgettable.",
  },
  {
    id: 6,
    boldText: "Department Stores:",
    text: "We attract new customers to explore and try the diverse product offerings in your large-scale retail space.",
  },
  {
    id: 7,
    boldText: "Fast Food Chains:",
    text: "We connect you with customers ready to enjoy quick, delicious meals from your fast food menu.",
  },
  {
    id: 8,
    boldText: "Fine Dining Establishments:",
    text: "We bring new customers to experience your exquisite cuisine and exceptional dining ambiance.",
  },
  {
    id: 9,
    boldText: "Food and Beverages:",
    text: "We help food and drink brands attract new customers ready to try your gourmet snacks or everyday essentials.",
  },
  {
    id: 10,
    boldText: "Health and Beauty:",
    text: "From skincare to cosmetics and wellness products, we connect you with new customers eager to experience your self-care offerings.",
  },
];
