import { keyframes, styled } from "styled-components";
import { ReactComponent as Correct } from "../../assets/icons/correctWhite.svg";
import { ReactComponent as LogoSvg } from "../../assets/icons/logoBig.svg";
import { ReactComponent as Background } from "../../assets/icons/bg.svg";

import { ReactComponent as InstaSvg } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookSvg } from "../../assets/icons/facebook.svg";
import { ReactComponent as LinkedInSvg } from "../../assets/icons/linkedin.svg";
import { ReactComponent as TwitterSvg } from "../../assets/icons/twitter.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;

  }
`;

export const LinkedIn = styled(LinkedInSvg)`
  width: 2.2rem;
  height: 2.2rem;
`;
export const Instagram = styled(InstaSvg)`
  width: 2.2rem;
  height: 2.2rem;
`;
export const Facebook = styled(FacebookSvg)`
  width: 2.2rem;
  height: 2.2rem;
`;
export const X = styled(TwitterSvg)`
  width: 2.2rem;
  height: 2.2rem;
`;

export const SectionRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15rem;
  flex-direction: row;
  @media (max-width: 1415px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const FadeIn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-in;
`;
export const BgRed2 = styled.div`
  position: absolute;
  top: 0;
  height: 10rem;
  right: 0;
  left: 0;
  background-color: #ffe5e4;
  width: 100%;
`;
export const BgRed = styled(Background)`
  position: absolute;
  top: 7rem;

  right: 0;
  left: 0;
`;
export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  background-color: #fff;
  align-items: center;
  width: 100%;
`;

export const SectionBlueColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const SectionBlue = styled.div`
  display: flex;
  gap: 5rem;
  overflow: ${(props) => (props.$hidden ? "hidden" : "visible")};
  width: 100%;
  max-width: 144rem;
  flex-direction: ${(props) => (props.$column ? "column" : "row")};
  position: relative;
  min-height: ${(props) => (props.$smallHeight ? "54rem" : "100dvh")};
  padding: 4rem;
  padding-top: ${(props) => (props.$firstSection ? "12rem" : "4rem")};
  height: ${(props) => (props.$smallHeight ? "54rem" : "100%")};
  background-color: #ebebf4;

  align-items: ${(props) =>
    props.$alignItemsCenter ? "center" : "flex-start"};
  @media (max-width: 2560px) {
    min-height: ${(props) => (props.$smallHeight ? "54rem" : "70dvh")};
  }
  @media (max-width: 1500px) {
    min-height: 100%;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Section = styled.div`
  display: flex;
  overflow: ${(props) => (props.$hidden ? "hidden" : "visible")};
  width: 100%;
  max-width: 144rem;
  flex-direction: ${(props) => (props.$column ? "column" : "row")};
  position: relative;
  min-height: ${(props) => (props.$smallHeight ? "54rem" : "100dvh")};
  padding: 4rem;
  padding-top: ${(props) => (props.$firstSection ? "12rem" : "4rem")};
  height: ${(props) => (props.$smallHeight ? "54rem" : "100%")};
  background-color: ${(props) => (props.$red ? "#FFE5E4" : "white")};
  justify-content: ${(props) =>
    props.$column ? "flex-start" : "space-around"};
  align-items: ${(props) =>
    props.$alignItemsCenter ? "center" : "flex-start"};
  @media (max-width: 2560px) {
    min-height: ${(props) => (props.$smallHeight ? "54rem" : "70dvh")};
  }
  @media (max-width: 1500px) {
    min-height: ${(props) => (props.$smallHeight ? "54rem" : "100dvh")};
  }
  @media (max-width: 490px) {
    padding: 10rem 2rem 10rem 2rem;
  }
`;

export const SectionSubtitle = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  max-width: ${(props) => (props.$big ? "104rem" : "61.7rem")};
  text-align: ${(props) => (props.$big ? "center" : "left")};
  z-index: 2;

  margin-top: 3.5rem;
  margin-bottom: 3rem;
`;
export const RedLine = styled.div`
  width: 5px;
  height: 29px;
  background: #fe5f55;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  margin-top: ${(props) => (props.$marginTop ? "16rem" : "0rem")};
  gap: 0.3rem;
`;

export const LabelInfo = styled.span`
  color: #212121;
  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
`;
export const LabelInfoText = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
`;

export const Label2 = styled.span`
  max-height: 2.9rem;
  padding: 0.9rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: ${(props) =>
    props.$offBorder ? "0rem .5rem .5rem 0rem" : "0.5rem"};
  background: ${(props) =>
    props.$light ? "rgba(46, 49, 144, 0.1)" : "#2e3190"};
  color: ${(props) => (props.$light ? "#212121" : "#fff")};
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;
export const Label = styled.span`
  width: 38.7rem;
  height: 61.6rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0 1rem 1rem 0;
  background: rgba(46, 49, 144, 0.1);

  color: #212121;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;

export const SectionTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: ${(props) => (props.$small ? "2.8rem" : "5.4rem")};
  line-height: ${(props) => (props.$small ? "unset" : "5.8rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  text-transform: capitalize;
`;
export const InformationText = styled.span`
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  color: #4d4d4d;
`;
export const SmallTitle = styled.span`
  font-family: "Proxima-Nova-Bold";
  font-size: 3.4rem;
`;
export const SmallLabel = styled.span`
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.8rem;
  text-transform: uppercase;
`;
export const TextDot = styled.div`
  max-width: 0.9rem;
  max-height: 0.9rem;
  min-width: 0.9rem;
  min-height: 0.9rem;
  border-radius: 50%;
  background-color: #2e3190;
  margin-top: 0.9rem;
`;
export const TextDotRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

export const SmallLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

export const SectionMediumTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: 4.4rem;
  line-height: ${(props) => (props.$small ? "unset" : "5.2rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  text-transform: capitalize;
`;

export const SectionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.2rem;
  z-index: 2;
  margin-bottom: ${(props) => (props.$marginBottom ? "0.5rem" : "0rem")};
  margin-top: ${(props) => (props.$marginTopOff ? "0rem" : "2.5rem")};
  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const YellowCircleTopRight = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;

  right: -20rem;
  top: -20rem;
`;

export const PersonalInformationContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  align-items: center;
  width: 100%;
  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const PersonalInformationContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
export const PersonalInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 2rem;
  width: 100%;
`;

export const OrangeCircleTopRight = styled.div`
  width: 41.1rem;
  height: 40.9rem;

  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(254, 96, 86, 0.4) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;

  left: -20rem;
  top: -20rem;
`;

export const YellowCircleBottom = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 411px;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  left: ${(props) => (!props.$left ? "unset" : "-20rem")};
  right: ${(props) => (props.$left ? "unset" : "-20rem")};
  bottom: 0rem;
`;
export const YellowCircle = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 411px;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  left: ${(props) => (!props.$left ? "unset" : "-20rem")};
  right: ${(props) => (props.$left ? "unset" : "-20rem")};
  top: 50%;
  transform: translateY(-50%);
`;

export const PricingCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;
export const PricingCard = styled.div`
  width: 38.7rem;
  height: 60rem;
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;
  gap: 2rem;

  border-radius: 1rem;
  background: ${(props) =>
    props.$blueGradient
      ? "linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(46, 49, 144, 0.30) 100%), #FE5F55"
      : "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.3) 100%), #fe5f55"};
  position: relative;
`;

export const Logo = styled(LogoSvg)`
  position: absolute;
  bottom: -3rem;
  height: 33rem;
  width: auto;
  opacity: 0.1;
  path {
    fill: #2e3190;
  }

  right: 5rem;
`;

export const PackageType = styled.span`
  color: #212121;

  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.5rem;
  line-height: 5.2rem; /* 346.667% */
  letter-spacing: 1.8px;
  text-transform: uppercase;
  display: flex;
  padding: 0 1.2rem;
  max-height: 4.8rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: ${(props) => (props.$yellow ? "#FFC324" : "#FFF")};
`;

export const PackageDescription = styled.span`
  color: #fff;

  font-family: "Proxima-Nova-Regular";
  margin-top: ${(props) => (props.$margin ? "0.5rem" : "0rem")};
  margin-bottom: ${(props) => (props.$margin ? "1rem" : "0rem")};
  font-size: 1.6rem;
  max-width: 90%;
  line-height: 2.5rem; /* 156.25% */
`;

export const LightText = styled.span`
  color: #fff;

  text-align: center;
  font-family: "Proxima-Nova-Regular";

  font-size: 1.3rem;
  line-height: 2rem; /* 156.25% */
`;

export const HorizontalLine = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
`;

export const Price = styled.span`
  color: #fff;

  font-family: "Proxima-Nova-Bold";
  font-size: ${(props) => (props.$small ? "2.5rem" : "5.5rem")};
  line-height: 5.2rem; /* 94.545% */
  letter-spacing: -0.11rem;
`;

export const PriceText = styled.span`
  color: #fff;

  margin-top: ${(props) => (props.$marginTopOff ? "0rem" : "-2rem")};
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  line-height: 2.5rem; /* 94.545% */
  letter-spacing: -0.11rem;
`;

export const PerColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const PerContainer = styled.div`
  display: flex;
  padding: 0rem 1.2rem;
  align-items: center;
  flex-direction: row;
  gap: 0.6rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.31);
`;

export const CorrectIcon = styled(Correct)``;

export const StyledButton = styled.button`
  border-radius: 1rem;
  border: 0.1rem solid #fff;
  background: #fff;
  height: 4.8rem;
  min-width: 26rem;
  width: 100%;
  padding: 0rem 2rem;
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: black;
  outline: none;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: "Proxima-Nova-Bold";
  transition: all 0.3s ease-in-out;
  margin-top: ${(props) => (props.$marginTop ? "1.5rem" : "0rem")};
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  &:active {
    scale: 0.99;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 1415px) {
    align-items: center;
  }
`;
export const InfoContainerRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`;

export const Star = styled.span`
  color: #fe5f55;
  margin-top: 0.65rem;

  font-family: "Proxima-Nova-Bold";
  font-size: 3.5rem;
  line-height: 2.9rem; /* 82.857% */
`;
export const InfoText = styled.span`
  color: #575757;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 82.857% */
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonPackage = styled.button`
  border-radius: 0.47rem;
  border: 0.12rem solid #2e3190;
  background: ${(props) => (!props.$active ? "#fff" : "#2e3190")};
  outline: none;
  cursor: pointer;

  color: ${(props) => (!props.$active ? "#000" : "#fff")};
  z-index: ${(props) => (props.$active ? 2 : 1)};
  text-align: center;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.8rem;
  line-height: 2.82rem; /* 156.502% */
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: ${(props) => (props.$right ? "0rem" : "-1rem")};
  border-bottom-right-radius: ${(props) => (props.$right ? "0rem" : "-1rem")};
  border-top-left-radius: ${(props) => (!props.$right ? "0rem" : "-1rem")};
  border-bottom-left-radius: ${(props) => (!props.$right ? "0rem" : "-1rem")};
`;

export const CustomPackageLabel = styled.span`
  margin-top: 4rem;
  margin-bottom: 3rem;

  color: #212121;
  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 6rem;
`;

export const RangeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 80%;
  height: 0.6rem;
  background: #b3b3b3;
  border-radius: 0.5rem;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2.4rem;
    height: 2.4rem;
    background: white;
    border: 0.3rem solid #e74c3c;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
`;

export const Tooltip = styled.div`
  background: white;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  /* border: 1px solid gray; */
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 1.4rem;
  color: #333;
  margin-top: 0.5rem;
`;

export const MultipleLocationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  background: #ebebf4;
  width: 100%;
  padding: 3rem 4rem;
  max-width: 120rem;
  margin-top: 8rem;
`;

export const MultipleLocationsTitle = styled.span`
  color: #212121;

  font-family: "Proxima-Nova-Bold";
  font-size: 2.4rem;

  margin-bottom: 1.5rem;
`;

export const MultipleLocationsRightSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 84rem;
  gap: 2rem;
`;

export const MultipleLocationsText = styled.span`
  color: #575757;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.7rem;
  line-height: 2.9rem; /* 170.588% */
`;

export const MultipleLocationsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: 0.1rem solid black;
  height: 4.8rem;

  color: #212121;
  font-family: "Proxima-Nova-SemiBold";

  max-width: 13rem;
  font-size: 1.6rem;

  cursor: pointer;
  outline: none;
  user-select: none;
  transition: all 0.3s ease-in-out;
  &:active {
    scale: 0.99;
    background-color: black;
    color: white;
  }
`;

export const StyledHorizontalLine = styled.div`
  width: 0.1rem;
  height: 22rem;
  margin-right: 3.4rem;
  margin-left: 3.4rem;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 22rem;
`;
export const TotalPrice = styled.span`
  color: #fe5f55;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  font-size: 4.7rem;
  line-height: 5.2rem; /* 110.638% */
  letter-spacing: -0.94px;
`;
export const TotalPriceText = styled.span`
  color: #4e4e4e;
  text-align: center;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  line-height: 2.5rem; /* 110.638% */
`;

export const HowItWorkLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  gap: 2.7rem;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.9rem;
`;

export const Step = styled.span`
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 50%;
  background-color: #fe5f55;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.8rem;
`;

export const StepLine = styled.div`
  width: 0.1rem;
  height: ${(props) => (props.$small ? "8.2rem" : "13.7rem")};
  background-color: #fe5f55;
`;

export const StepTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.8rem;
`;
export const StepText = styled.span`
  color: #4d4d4d;
  max-width: 51.6rem;

  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  margin-top: ${(props) => (props.$marginTop ? "3.4rem" : "0rem")};
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 61.2rem;
  width: 100%;
`;

export const HowItWorksImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
`;

export const HowItWorksImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Img1 = styled.img`
  width: 26.9rem;
  height: 25.7rem;
  border-radius: 1rem;
  object-fit: cover;
`;
export const Img2 = styled.img`
  width: 26.9rem;
  height: 44.3rem;
  border-radius: 1rem;
  object-fit: cover;
`;

export const LightBlueContainerLeft = styled.div`
  display: flex;
  gap: 2rem;
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
  background: #ebebf4;
  height: 38rem;
  width: 18.3rem;
  margin-right: -5rem;
  position: relative;
  padding: 4.5rem;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1415px) {
    height: fit-content;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
    margin-bottom: -2.5rem;
    margin-right: 0rem;
  }
`;
export const LightBlueContainerRight = styled.div`
  display: flex;
  position: relative;
  gap: 2rem;
  flex-direction: column;
  border-radius: 1rem;
  padding: 3rem;
  height: 38rem;
  width: 80rem;
  border: 1px solid rgba(0, 0, 0, 0);
  background: #ebebf4;
  margin-left: -5rem;
  overflow: hidden;
  padding-left: 10rem;

  @media (max-width: 1415px) {
    height: fit-content;
    width: 100%;

    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin-left: 0;
  }
`;
export const StyledProfilePhoto = styled.img`
  width: 47rem;
  height: 47rem;
  z-index: 2;
  object-fit: cover;
  border-radius: 1rem;

  display: none;
  @media (max-width: 1415px) {
    display: flex;
  }
  @media (max-width: 520px) {
    width: 100%;
    height: auto;
  }
`;
export const ProfilePhoto = styled.img`
  width: 47rem;
  height: 47rem;
  z-index: 2;
  object-fit: cover;
  border-radius: 1rem;
  @media (max-width: 1415px) {
    display: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-height: 4.55rem;
  max-height: 4.55rem;
  min-width: 4.55rem;
  max-width: 4.55rem;
  cursor: pointer;
  background: linear-gradient(0deg, #fe5f55 0%, #2e3190 100%);
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const ProfileContainer = styled.div`
  position: relative;
  width: 28.5rem;
  height: 43.3rem;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 11.1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  clip-path: ellipse(100% 100% at 50% 100%);
  background: #ffe5e4;
  border-radius: 1rem 1rem;
`;

export const Name = styled.span`
  color: #fe5f55;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  font-size: 2rem;
`;
export const Role = styled.span`
  color: #4c4c4c;
  text-align: center;
  font-family: "Proxima-Nova-Regular";
  margin-top: -0.5rem;
  font-size: 1.8rem;
`;

export const InputContainerColum = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 2rem;
`;
export const InputContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
`;

export const Input = styled.input`
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  background-color: transparent;
  outline: none;

  color: rgba(115, 115, 115, 1);
  font-family: "Proxima-Nova-Regular";
  font-size: 1.4rem;

  width: 100%;

  height: 5.5rem;
`;

export const Textarea = styled.textarea`
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  background-color: transparent;
  outline: none;

  color: rgba(115, 115, 115, 1);
  font-family: "Proxima-Nova-Regular";
  font-size: 1.4rem;

  width: 100%;
  height: 11rem;
`;

export const InputLabel = styled.span`
  color: ${(props) =>
    props.$red ? "rgba(255, 21, 21, 0.80)" : "rgba(0, 0, 0, 1)"};
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  text-transform: capitalize;
`;

export const PhotoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const PhotoRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const PhotoShort = styled.img`
  border-radius: 1rem;
  width: 24rem;
  height: 25.6rem;
  object-fit: cover;

  @media (max-width: 560px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
  }
`;
export const PhotoLong = styled.img`
  border-radius: 1rem;
  object-fit: cover;

  height: 43rem;
  width: 24rem;
  @media (max-width: 560px) {
    width: 100%;
    height: auto;

    aspect-ratio: 6 / 9;
  }
`;
