import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Section,
  SectionTitle,
  BgRed,
  SectionSubtitle,
  Input,
  Inputs,
  Image,
} from "./EmagazinePage.styles";

import Image1 from "../../assets/images/redesign/magazine.png";
import Image2 from "../../assets/images/redesign/image61.png";
import Button from "../../components/utils/Button/Button";

import openToast from "../../utils/toast";

import { FORM_TYPES } from "../../utils/constants";

import { websiteForm } from "../../store/slice/posts/asyncThunk";
import { selectWebsiteForm } from "../../store/slice/posts/slice";
import { isValidEmail } from "../../utils/utils";
import { StyledPhoneInput } from "../SupportPage/SupportPage.styles";
import { isValidPhoneNumber } from "libphonenumber-js";

const EmagazinePageSections = {
  HeroSection: () => {
    const [focus, setFocus] = useState(false);
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const { loading } = useSelector(selectWebsiteForm);

    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [checkValidation, setCheckValidation] = useState(false);

    const handleOnChange = (value, setValue) => {
      setValue(value);
    };

    const resetValues = () => {
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setCheckValidation(false);
      setFocus(false);
    };

    const handleSubmit = (e) => {
      setCheckValidation(true);

      if (fullName && isValidEmail(email)) {
        dispatch(
          websiteForm({
            email: email,
            fullName: fullName,
            type: FORM_TYPES.E_MAGAZINE,

            data: { additionalInfo: `Phone Number: ${phoneNumber}` },
          })
        )
          .unwrap()
          .then(() => {
            openToast(
              toastId,
              "submit-message",
              "success",
              `Thank you, ${fullName}! You've successfully subscribed.`
            );
            resetValues();
          })
          .catch((err) =>
            openToast(toastId, "error-message", "error", err.message)
          );
      }
    };
    return (
      <Section $alignItemsCenter $column $firstSection>
        <BgRed />

        <SectionTitle>
          Get the latest <SectionTitle $red>Issue</SectionTitle>
        </SectionTitle>

        <SectionSubtitle $big>
          Stay informed with the latest insights on how innovative brands are
          utilizing EyCrowd's dynamic community and cutting-edge “brand
          experiences” to bring fresh new faces to physical stores, digital
          platforms, and product touch-points.Content includes detailed case
          studies, examples of authentic UGC, leading professional profiles and
          more.
        </SectionSubtitle>

        <Inputs>
          <Input
            placeholder="Full Name"
            value={fullName}
            $error={checkValidation && !fullName}
            onChange={(e) => {
              handleOnChange(e.target.value, setFullName);
            }}
          />
          <Input
            placeholder="Email Address"
            $error={checkValidation && (!isValidEmail(email) || !email)}
            value={email}
            onChange={(e) => {
              handleOnChange(e.target.value, setEmail);
            }}
          />
          <StyledPhoneInput
            inputStyle={{
              width: "100%",
              border: "none",
              outline: "none",
              zIndex: 20,
              borderRadius: "1rem",
              background: "transparent",
            }}
            buttonStyle={{
              border: "none",
              outline: "none",
              background: "transparent",
              borderRadius: "1rem",
              zIndex: 20,
            }}
            searchStyle={{
              zIndex: 20,
              borderRadius: "1rem",
              marginLeft: -4,
              width: "99%",
            }}
            containerStyle={{
              width: "100%",
              zIndex: 20,
              borderRadius: "1rem",
              border: focus && "1px solid rgba(255, 255, 255, 0.5)",
            }}
            dropdownStyle={{
              border: "none",
              outline: "none",
              zIndex: 20,
              borderRadius: "1rem",
              background: "white",
            }}
            placeholder="Phone Number"
            value={phoneNumber}
            enableSearch
            disableSearchIcon
            country={"us"}
            onChange={(value, _, e, formattedValue) => {
              if (value === "3810") {
                return;
              }
              setPhoneNumber(formattedValue);
            }}
            $error={
              checkValidation &&
              (!isValidPhoneNumber(phoneNumber) || !phoneNumber)
            }
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => setFocus(false)}
          />
          <Button
            title={"Subscribe to Our Magazine"}
            onClick={handleSubmit}
            disabled={loading}
            containerStyle={{ width: "100%", maxWidth: 250 }}
            loading={loading}
          />
        </Inputs>

        <Image src={Image2} />
      </Section>
    );
  },
};

export default EmagazinePageSections;
