import { keyframes, styled } from "styled-components";
import { ReactComponent as LogoSvg } from "../../assets/icons/logoBig.svg";

import { ReactComponent as ByEycSvg } from "../../assets/icons/byEyc.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;

  }
`;

export const FadeIn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-in;
`;

export const SectionColum = styled.div`
  min-height: ${(props) => (props.$small ? "100%" : "80dvh")};
  height: 100%;
  overflow: hidden;
  max-width: 144rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.$center ? "center" : "space-around")};
  gap: 5rem;
  position: relative;
  padding: ${(props) => (props.$small ? "8rem" : "3rem")};
  background-color: ${(props) => (props.$white ? "#fff" : "#FFE5E4;")};

  @media (max-width: 1000px) {
    padding: 10rem 4rem !important;
  }
`;

export const Section = styled.div`
  min-height: 80dvh;
  height: 100%;
  overflow: hidden;
  max-width: 144rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 5rem;
  position: relative;
  padding: 3rem;
  flex-wrap: wrap;
  background-color: ${(props) => (props.$white ? "#fff" : "#FFE5E4;")};
  @media (max-width: 1440px) {
    min-height: 100%;
    padding: 12rem 3rem;
  }
  @media (max-width: 490px) {
    padding: 10rem 2rem 10rem 2rem;
  }
`;
export const SectionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
export const SectionTitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 1470px) {
    margin-bottom: 0 !important;
  }
`;
export const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
export const Image = styled.img`
  border-radius: 1rem;
  width: 65rem;
  height: 56.5rem;
  object-fit: cover;
  @media (max-width: 650px) {
    width: 100%;
    height: auto;
  }
`;
export const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;

  gap: 0.3rem;
`;

export const Label2 = styled.span`
  max-height: 2.9rem;
  padding: 0.9rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: ${(props) =>
    props.$offBorder ? "0rem .5rem .5rem 0rem" : "0.5rem"};
  background: ${(props) =>
    props.$light ? "rgba(46, 49, 144, 0.1)" : "#2e3190"};
  color: ${(props) => (props.$light ? "#212121" : "#fff")};
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  letter-spacing: -0.32px;
`;
export const SectionSubtitle = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.8rem;
  line-height: 2.9rem; /* 161.111% */
  letter-spacing: 0.36px;
  max-width: 64rem;
  z-index: 2;

  margin-top: 3.5rem;
  margin-bottom: 3rem;
  text-transform: capitalize;
  @media (max-width: 600px) {
    text-align: center;
    margin-bottom: -2rem;
  }
`;

export const OrangeCircle = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 411px;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(254, 96, 86, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  left: ${(props) => (!props.$left ? "unset" : "-20rem")};
  right: ${(props) => (props.$left ? "unset" : "-20rem")};
  top: 50%;
  transform: translateY(-50%);
`;
export const YellowCircle = styled.div`
  width: 41.1rem;
  height: 40.9rem;
  flex-shrink: 0;
  border-radius: 411px;
  background: radial-gradient(
    93.61% 53.09% at 50% 47.05%,
    rgba(255, 215, 0, 0.65) 0%,
    rgba(14, 17, 16, 0) 100%
  );

  filter: blur(176.4499969482422px);
  position: absolute;
  left: ${(props) => (!props.$left ? "unset" : "-20rem")};
  right: ${(props) => (props.$left ? "unset" : "-20rem")};
  top: 50%;
  transform: translateY(-50%);
`;

export const SectionTitle = styled.span`
  color: ${(props) => (props.$red ? "#fe5f55" : "#212121")};
  font-family: "Proxima-Nova-Black";
  font-size: ${(props) => (props.$small ? "2.8rem" : "5.4rem")};
  line-height: ${(props) => (props.$small ? "unset" : "5.8rem")};
  letter-spacing: ${(props) => (props.$small ? "0.56px" : "-1.08px")};
  text-transform: capitalize;
  max-width: 60rem;
  text-align: ${(props) => (props.$center ? "center" : "left")};
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const ByEyc = styled(ByEycSvg)`
  margin-top: 7rem;
`;
export const RedLine = styled.div`
  width: 5px;
  height: 29px;
  background: #fe5f55;
`;

export const Logo = styled(LogoSvg)`
  position: absolute;
  left: -5rem;
  height: 60rem;
  width: auto;
  top: 50%;
  path {
    fill: #fe5f55;
  }
  opacity: 0.1;
  transform: translateY(-50%);
`;

export const PhotoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

export const PhotoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 600px) {
    width: 50%;
    height: auto;
  }
`;

export const PhotoShort = styled.img`
  width: 24rem;
  height: 24rem;
  object-fit: cover;
  border-radius: 1rem;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;
export const PhotoLong = styled.img`
  width: 24rem;
  height: 40rem;
  border-radius: 1rem;
  object-fit: cover;

  @media (max-width: 600px) {
    width: 50%;
    height: auto;
  }
`;
export const PhotoLongWidth = styled.img`
  width: 100%;
  max-width: 120rem;
  height: 23rem;
  border-radius: 1rem;
  object-fit: cover;
`;

export const Photo = styled.img`
  width: 59rem;
  height: 39rem;
  border-radius: 1rem;
  object-fit: cover;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
`;

export const IconContainer = styled.div`
  width: 5.2rem;
  height: 5.2rem;
  background-color: #2e3190;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: -5rem;
  @media (max-width: 600px) {
    align-self: center;
  }
`;
export const Number = styled.span`
  color: #fff;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  font-size: 2.6rem;
`;

export const Text = styled.span`
  color: ${(props) => (props.$bold ? "#000" : "#4d4d4d")};
  font-family: ${(props) =>
    props.$bold ? "Proxima-Nova-Bold" : "Proxima-Nova-Regular"};
  font-size: 1.8rem;
  max-width: ${(props) => (props.$maxWidth ? "52rem" : "unset")};
`;
export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

export const IconColumn = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.6rem;
`;
export const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$maxWidth ? "flex-start" : "center")};
  flex-wrap: wrap;
  gap: 0.6rem;
  max-width: ${(props) => (props.$maxWidth ? "17rem" : "unset")};
`;

export const IconText = styled.span`
  font-family: ${(props) =>
    props.$small ? "Proxima-Nova-Regular" : "Proxima-Nova-Bold"};
  font-size: ${(props) => (props.$small ? "1.2rem" : "1.5rem")};
  max-width: 13rem;
  color: ${(props) => (props.$small ? "#606060" : "#4C4C4C")};
`;
export const IconTitle = styled.span`
  font-family: "Proxima-Nova-Bold";
  font-size: 1.8rem;
`;

export const OurUserSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4rem;
  @media (max-width: 1470px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const OurUserPhoto = styled.img`
  width: 30rem;
  height: 40rem;
  object-fit: cover;
`;

export const OurUserPhotoDiv = styled.div`
  position: relative;
`;

export const InfoContainer = styled.div`
  position: absolute;
  border-radius: 1rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  bottom: -2rem;
  left: 2rem;
  padding: 1.5rem;
`;

export const Info = styled.span`
  color: ${(props) => (props.$bold ? "#000" : "#4d4d4d")};
  font-family: ${(props) =>
    props.$bold ? "Proxima-Nova-Bold" : "Proxima-Nova-Regular"};
  font-size: 2rem;
`;

export const BoldText = styled.span`
  color: #4d4d4d;
  font-family: "Proxima-Nova-Bold";
  font-size: 2.9rem;
  align-self: center;
  max-width: 67.5rem;
  @media (max-width: 1470px) {
    text-align: center;
  }
`;

export const ConnectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
