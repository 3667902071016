import React, { useState } from "react";
import styled from "styled-components";

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  z-index: 5;
  border-radius: 1rem;
  margin-bottom: 5rem;
`;

const Label = styled.span`
  text-align: center;
  font-family: "Proxima-Nova-SemiBold";
  font-size: 1.6rem;
  /* white-space: nowrap; */
`;
const Text = styled.span`
  text-align: center;
  color: #5f5f5f;
  font-family: "Proxima-Nova-Regular";
  font-size: 1.6rem;
  @media (max-width: 1300px) {
    display: none;
  }
`;

const Switch = styled.div`
  width: 7.5rem;
  height: 3.8rem;
  background: ${(props) => (props.active ? "#fe5f55" : "#ccc")};
  border-radius: 3.8rem;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;

  box-shadow: 7px 6px 15.1px rgba(0, 0, 0, 0.16);

  &::before {
    content: "";
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: ${(props) => (props.active ? "calc(100% - 3.3rem)" : "3px")};
    transform: translateY(-50%);
    transition: left 0.3s ease;
  }

  @media (max-width: 430px) {
    width: 5.5rem;
    height: 2.7rem;

    &::before {
      content: "";
      position: absolute;
      width: 2rem;
      height: 2rem;
      background: white;
      border-radius: 50%;
      top: 50%;
      left: ${(props) => (props.active ? "calc(100% - 2.4rem)" : "3px")};
      transform: translateY(-50%);
      transition: left 0.3s ease;
    }
  }
`;
const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const ToggleSwitch = ({ marginTop }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <SwitchContainer
      style={{
        marginTop: marginTop ? 140 : 0,
        marginBottom: marginTop ? -40 : 0,
      }}
    >
      <TextColumn>
        <Label>I'm a business leader.</Label>
        <Text>How can I drive growth with EyCrowd?</Text>
      </TextColumn>
      <Switch active={isActive} onClick={() => setIsActive(!isActive)} />
      <TextColumn>
        <Label>I enjoy the EyCrowd App</Label>
        <Text>How can I earn by having fun and trying new brands?</Text>
      </TextColumn>
    </SwitchContainer>
  );
};

export default ToggleSwitch;
